import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  Text,
  Link,
  FormErrorMessage,
} from "@chakra-ui/react";
import React from "react";
import { FcGoogle } from "react-icons/fc";
import { GiCheckMark } from "react-icons/gi";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { PublicRoutes } from "../../routes/Routes";
import { Field, Form, Formik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  login,
  signInToGoogle,
} from "../../services/authentication/authentication.service";
import { useAppStore } from "../../store/store";
import toast from "react-hot-toast";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

function validateValues(values) {
  let errors = {};
  if (!values.password) {
    errors.password = "Password is required";
  } else if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
}

const Login = () => {
  const setUserIsLoggedIn = useAppStore((state) => state.setUserIsLoggedIn);
  const setUserIsAdmin = useAppStore((state) => state.setUserIsAdmin);
  const setUserData = useAppStore((state) => state.setUserData);
  const setUserToken = useAppStore((state) => state.setUserToken);
  const navigate = useNavigate();
  const { mutate: loginMutate, isPending } = useMutation({
    mutationKey: ["login"],
    mutationFn: (values) => {
      const res = login(values?.email, values?.password);
      return res;
    },
    onSuccess(data) {
      //set userdata and token
      localStorage.setItem(
        "BRSUserData",
        JSON.stringify(data?.data?.data?.user)
      );
      localStorage.setItem("BRSUserToken", JSON.stringify(data?.data?.token));
      setUserIsLoggedIn(true);
      setUserData(data?.data?.data?.user);
      setUserToken(data?.data?.token);
      if (data?.data?.data?.user.role === "admin") {
        setUserIsAdmin(true);
      }
      navigate("/account/listings");
    },
    onError(data) {
      toast.error(data?.response?.data?.message);
    },
  });

  return (
    <Box>
      <HStack bg="base.secondary" pt="80px" w="100%" h="200px">
        <Text color="base.white" fontSize="40px" fontWeight="800" mx="auto">
          Log In
        </Text>
      </HStack>
      <HStack className="p-[2rem] flex max-[860px]:!flex-col !gap-[1rem] !items-start">
        <Box className="w-full flex flex-col gap-[1rem] ">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validate={validateValues}
            onSubmit={async (values, actions) => {
              // await handleRegistration({ ...values });
              loginMutate(values);
              actions.setSubmitting(false);
            }}
          >
            {(props) => (
              <Form className="flex flex-col gap-[1rem]">
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel className="!m-0 !mb-[0.15rem] ">
                        Email
                      </FormLabel>
                      <Input {...field} placeholder="email" type="email" />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel className="!m-0 !mb-[0.15rem] ">
                        Password
                      </FormLabel>
                      <Input
                        {...field}
                        placeholder="Password"
                        type="password"
                      />
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  mt={4}
                  colorScheme="teal"
                  isLoading={isPending}
                  type="submit"
                >
                  Login
                </Button>
              </Form>
            )}
          </Formik>
          <div className="flex items-center gap-[3px]  ">
            <div className="w-full h-[1px] bg-orange"></div>
            <Text className="text-grey font-[600]">Or</Text>
            <div className="w-full h-[1px] bg-orange"></div>
          </div>
          {/* <Button
            mt={4}
            colorScheme="teal"
            isLoading={false}
            className="flex gap-[10px] items-center !m-0 "
            type="submit"
          >
            <Icon as={FcGoogle} />
            Sign in with google
          </Button> */}
          <GoogleLogin
            onSuccess={async (credentialResponse) => {
              try {
                const decoded = jwtDecode(credentialResponse.credential);
                const res = await signInToGoogle(decoded?.email, decoded?.name);
                localStorage.setItem(
                  "BRSUserData",
                  JSON.stringify(res?.data?.data?.user)
                );
                localStorage.setItem(
                  "BRSUserToken",
                  JSON.stringify(res?.data?.token)
                );
                setUserIsLoggedIn(true);
                setUserData(res?.data?.data?.user);
                setUserToken(res?.data?.token);
                if (res?.data?.data?.user.role === "admin") {
                  setUserIsAdmin(true);
                }
                navigate("/account/listings");
              } catch (e) {
                toast.error(e?.message);
              }
            }}
            onError={() => {
              toast.error("Login Failed, Please try again later!");
            }}
          />

          <Text>
            By continuing I understand and agree with Property24’s{" "}
            <Link className="!text-orange font-[700] ">Terms & Conditions</Link>{" "}
            and{" "}
            <Link className="!text-orange font-[700] ">Privacy Policy.</Link>
          </Text>
        </Box>
        <Box className="w-full pt-[2rem] pr-[2rem] flex flex-col  ">
          <Text>Don't have an account?</Text>
          <Text>Register now and get all these benefits:</Text>
          <HStack>
            <Icon as={GiCheckMark} />
            <Text>Manage and view your property alerts</Text>
          </HStack>
          <HStack>
            <Icon as={GiCheckMark} />
            <Text>Favourite and add notes to properties</Text>
          </HStack>
          <HStack>
            <Icon as={GiCheckMark} />
            <Text>Quickly contact agents with saved details</Text>
          </HStack>
          <Link
            className="!text-orange font-[700] "
            as={ReactRouterLink}
            to={PublicRoutes.REGISTER}
          >
            Register.
          </Link>
        </Box>
      </HStack>
    </Box>
  );
};

export default Login;

import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const RentOptions = () => {
  return (
    <Box
      bg="#fff"
      w="70%"
      h="100%"
      display="flex"
      justifyContent="space-evenly"
      fontWeight="700"
      fontSize="15px"
      p="40px 0"
      margin="auto"
    >
      <Box>
        <Text mb="15px">Looking to Rent?</Text>
        <Box color="#537cd8" display="flex" flexDirection="column" gap="15px">
          <Link
            to={"/view-listing?"}
            state={{
              offerType: "on-rent",
              propertyType: "house",
            }}
          >
            <Text className="hover-underline-animation">Houses for Rent</Text>
          </Link>
          <Link
            to={"/view-listing?"}
            state={{
              offerType: "on-rent",
              propertyType: "apartments",
            }}
          >
            <Text className="hover-underline-animation">
              Apartment/Flats for Rent
            </Text>
          </Link>
          <Link
            to={"/view-listing?"}
            state={{
              offerType: "on-rent",
              propertyType: "commercial",
            }}
          >
            <Text className="hover-underline-animation">
              Commercial Property to Rent
            </Text>
          </Link>
        </Box>
      </Box>
      <Box>
        <Box
          color="#537cd8"
          display="flex"
          flexDirection="column"
          gap="15px"
          mt="35px"
        >
          {/* <Text className="hover-underline-animation">
            Retirement Property to Rent
          </Text> */}
          <Link
            to={"/view-listing?"}
            state={{
              offerType: "on-rent",
              propertyType: "industrial",
            }}
          >
            <Text className="hover-underline-animation">
              Industrial Property to Rent
            </Text>
          </Link>
        </Box>
      </Box>
      <Box>
        <Text mb="15px">List Privately</Text>
        <Box color="#537cd8" display="flex" flexDirection="column" gap="15px">
          <Link to={"/account/create"}>
            <Text className="hover-underline-animation">
              Rent Your Property
            </Text>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default RentOptions;

import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import whatsapp from "../../assets/img/whatsapp.png";
import woman from "../../assets/img/wowo.webp";
import PropertyCard from "../../components/atoms/PropertyCard";
import Tag from "../../components/atoms/Tag";
import {
  HeartSvg,
  HomeSvg,
  LeftArrowSvg,
  LocationSvg,
  PrintSvg,
  RightArrowSvg,
  SwapSvg,
  TimeSvg,
  WarningSvg,
} from "../../components/atoms/svg";
import { featuredListings } from "../../mock/featuredData";
import ViewNumber from "../../components/atoms/ViewNumber";
import ViewWhatsapp from "../../components/atoms/ViewWhatsapp";
import LongSlider from "../../components/atoms/LongSlider";
import { useQuery } from "@tanstack/react-query";
import { getPropertyApi, getUserPropertiesApi } from "../../services/api";
import { capFirstLetter } from "../../helper/helpers";
import { useAppStore } from "../../store/store";
import VideoPlayer from "../../components/atoms/VideoPlayer";

const ViewProperty = () => {
  const { id } = useParams();
  const userData = useAppStore((state) => state.userData);
  const { isLoading, error, data } = useQuery({
    queryKey: ["getProperty", id],
    queryFn: async () => {
      const res = await getPropertyApi(id);
      return res?.data?.data;
    },
    onSuccess: () => {},
  });

  const agentId = data?.user?._id;

  const {
    isLoading: agentIsLoading,
    error: agentError,
    data: agentData,
  } = useQuery({
    queryKey: ["getAgentProperties"],
    queryFn: async () => {
      const res = await getUserPropertiesApi(agentId, 1, 4);
      return res?.data?.data;
    },
    enabled: !!agentId,
  });

  const navigate = useNavigate();

  if (isLoading)
    return (
      <div className="container m-auto gap-[30px] max-w-[1300px] w-full flex items-center justify-center min-h-[100vh]">
        <p className=" font-500">Loading...</p>
      </div>
    );
  if (error)
    return (
      <div className="container m-auto gap-[30px] max-w-[1300px] w-full flex items-center justify-center min-h-[100vh]">
        <p className=" font-[500] text-red ">Error, please try again...</p>
      </div>
    );

  return (
    <div className="flex flex-col pt-[120px] pb-[50px] max-md:!px-[20px] px-[50px] container m-auto  max-w-[1300px]">
      <div className="TopPart w-full flex max-lg:flex-col  gap-[30px]">
        <div className="flex flex-[1_1_65%] flex-col ">
          <div className="rounded-[] h-[470px] w-full bg-grey rounded-t-[10px] overflow-hidden  ">
            <div className="w-full h-full">
              {data?.images.length >= 3 ? (
                <div className="grid w-full h-full grid-cols-2 grid-rows-3">
                  <img
                    className="object-cover object-center w-full h-full col-span-2 row-span-2"
                    src={data?.images[0]}
                    alt="house"
                  />
                  <img
                    className="object-cover object-center w-full h-full"
                    src={data?.images[1]}
                    alt="house"
                  />
                  <div className="relative w-full h-full ">
                    {data?.images.length > 3 && (
                      <>
                        <p className="text-white text-[2rem] h-fit w-fit font-[600] absolute top-0 left-0 right-0 bottom-0 m-auto z-[2] cursor-pointer">
                          +{data?.images.length - 3}
                        </p>
                        <div
                          className="bg-[#000000] opacity-[0.6] absolute top-0 left-0 right-0 bottom-0 z-[1] cursor-pointer"
                          onClick={() =>
                            navigate("/photos", { state: data?.images })
                          }
                        ></div>
                      </>
                    )}
                    <img
                      className="object-cover object-center w-full h-full"
                      src={data?.images[2]}
                      alt="house"
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={`"w-full h-full grid grid-cols-${
                    data?.images.length > 1 ? "2" : "1"
                  }`}
                >
                  {data?.images.map((imgO, index) => {
                    return (
                      <img
                        className={`w-full h-full object-cover object-center `}
                        src={imgO}
                        key={index}
                        alt="house"
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          <div className="border-[1px] border-[#F2F4F8] bg-[#fdfdfe] rounded-b-[10px] p-[15px] pb-[30px] ">
            <div className="flex items-center gap-[5px] ">
              <div className="h-[20px] w-[20px] rounded-full bg-[#D5E3EE] flex items-center justify-center">
                <TimeSvg className="" />
              </div>
              <p className="font-[500] ">2 months ago</p>
            </div>
            <h1 className="text-[24px] font-[700] mt-[1rem] ">{data?.title}</h1>
            <div className="mt-[5px] flex gap-[10px] ">
              <Tag
                type="big"
                svg={<HomeSvg className="" color="#537CD9" />}
                text={
                  data?.propertyType === "apartment"
                    ? "Apartments/Flats"
                    : data?.propertyType === "house"
                    ? "House"
                    : data?.propertyType
                }
                color={"#537CD9"}
              />
              <Tag
                type="big"
                svg={<LocationSvg className="" />}
                text={data?.state}
              />
            </div>
            <p className="mt-[0.5rem]">{data?.address}</p>
            <h1 className="text-[24px] font-[700] mt-[1rem] ">
              N{data?.price || 0}{" "}
              {data?.offerType === "on-rent" &&
                data?.paymentDuration &&
                `/ ${
                  data?.paymentDuration === "annum"
                    ? "Year"
                    : data?.paymentDuration === "monthly"
                    ? "Month"
                    : data?.paymentDuration === "weekly"
                    ? "Week"
                    : data?.paymentDuration === "daily"
                    ? "Day"
                    : data?.paymentDuration
                }`}
            </h1>
            <div className="flex flex-wrap gap-[5px] mt-[0.5rem]">
              <Tag type="small" text={"Bedrooms"} value={data?.bedrooms || 0} />
              <Tag
                type="small"
                text={"Bathrooms"}
                value={data?.bathrooms || 0}
              />
              <Tag
                type="small"
                text={"Living Rooms"}
                value={data?.livingroom || 0}
              />
              {/* <Tag type="small" text={"Year Built"} value={0} />
              <Tag type="small" text={"Property Size"} value={"35 ft2"} /> */}
            </div>

            <h1 className="text-[24px] font-[700] mt-[1.5rem] ">Description</h1>
            <h1 className="text-[18px] text-grey font-[500] mt-[0.5rem] ">
              {data?.description}
              {/* Well furnished 2 bedroom flat in a compound with 3 others • Air
              Conditioned • Interlocking • 24hrs Electricity • 24hrs Running
              Water • Location – An estate in Jabi */}
            </h1>

            <h1 className="text-[24px] font-[700] mt-[1.5rem] ">Features</h1>
            <div className="flex justify-between flex-wrap w-full gap-[5px] gap-y-[15px] mt-[0.5rem]">
              {data?.propertyFeatures.map((datum, index) => {
                return <Tag type="features" text={datum} />;
              })}
            </div>

            <h1 className="text-[24px] font-[700] mt-[1.5rem] ">Video</h1>
            <div className="w-full">
              {data?.videoUrl && <VideoPlayer videoUrl={data?.videoUrl} />}
            </div>
          </div>
        </div>
        <div className="flex flex-[1_1_35%] relative">
          <div className="w-full sticky top-[100px] h-fit ">
            <div className="h-fit w-full  rounded-[10px] border-[#F2F4F8] border-[1px] ">
              <div className="w-full rounded-t-[10px] bg-[#FDFDFE] px-[30px] py-[30px] flex gap-[15px] items-center ">
                <Link
                  to={"/user/admin"}
                  className="block group/img h-[60px] w-[60px] bg-grey rounded-full overflow-hidden "
                >
                  <img
                    src={
                      data?.user?.photo === "default.jpg"
                        ? woman
                        : data?.user?.photo
                    }
                    alt="profile"
                    className="group-hover/img:scale-[1.1] transition-all duration-[450] ease-in-out"
                  />
                </Link>
                <div className="flex flex-col gap-[3px]  ">
                  <p className="font-[700] text-[20px] leading-[24px] truncate w-full  ">
                    {capFirstLetter(data?.user?.name.split(" ")[0] || "")}{" "}
                    {capFirstLetter(data?.user?.name.split(" ")[1] || "")}
                  </p>
                  <Link
                    to={"/user/admin"}
                    state={data?.user}
                    className="text-purple underline font-[700] text-[18px] leading-[24px] "
                  >
                    See all ads
                  </Link>
                </div>
              </div>
              <ViewNumber number={String(data?.user?.phoneNum)} />
            </div>

            {userData?.isWhatsapp && (
              <ViewWhatsapp
                link={`https://api.whatsapp.com/send?phone=${String(
                  userData?.phoneNum
                ).replace("+", "")}`}
              />
            )}

            {/* <div className="h-fit w-full  rounded-[10px] border-[#F2F4F8] border-[1px] mt-[2rem] px-[30px] py-[15px] flex items-center justify-center gap-[15px]">
              <div className="h-[50px] w-[50px] rounded-full border-[1px] border-lightGrey flex items-center justify-center cursor-pointer ">
                <HeartSvg className={""} color={"#2A3946"} />
              </div>
              <div className="h-[50px] w-[50px] rounded-full border-[1px] border-lightGrey flex items-center justify-center cursor-pointer ">
                <SwapSvg className={""} color={"#2A3946"} />
              </div>
              <div className="h-[50px] w-[50px] rounded-full border-[1px] border-lightGrey flex items-center justify-center cursor-pointer ">
                <PrintSvg className={""} color={"#2A3946"} />
              </div>
            </div> */}

            <div className="flex items-center gap-[10px] justify-center mt-[2rem] cursor-pointer ">
              <WarningSvg className="" />
              <p className="text-red font-[600]">Report abuse</p>
            </div>
          </div>
        </div>
      </div>

      <LongSlider
        data={agentData}
        title={"More from this Agent"}
        link={"/user/admin"}
        state={data?.user}
        linkTitle={`Display all from ${
          capFirstLetter(data?.user?.name.split(" ")[0] || "") +
          " " +
          capFirstLetter(data?.user?.name.split(" ")[1] || "")
        }`}
      />

      <LongSlider
        title={"You may also like.."}
        link={""}
        linkTitle={"Start a new search"}
      />
    </div>
  );
};

export default ViewProperty;

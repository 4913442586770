import { Box, Text } from "@chakra-ui/react";
import React from "react";

const AdviceOptions = () => {
  return (
    <Box
      bg="#fff"
      w="70%"
      h="100%"
      display="flex"
      justifyContent="space-evenly"
      fontWeight="700"
      fontSize="15px"
      p="40px 0"
      margin="auto"
    >
      <Box>
        <Text mb="15px">Articles</Text>
        <Box color="#537cd8" display="flex" flexDirection="column" gap="15px">
          <Text className="hover-underline-animation">Buyers Guide</Text>
          <Text className="hover-underline-animation">Property Advise</Text>
        </Box>
      </Box>

      <Box>
        <Text mb="15px">Helpful Links</Text>
        <Box color="#537cd8" display="flex" flexDirection="column" gap="15px">
          <Text className="hover-underline-animation">Property News</Text>
          <Text className="hover-underline-animation">
            Commercial Property News
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default AdviceOptions;

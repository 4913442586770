import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const MiniNav = () => {
  useEffect(() => {}, []);
  return (
    <div className="w-full bg-[#fdfdfe] h-fit">
      <div className="w-full  h-[180px] pt-[120px] flex items-end max-md:!px-[20px] px-[50px] container m-auto max-w-[1300px] overflow-auto">
        <NavItem title={"Add New"} path={"/account/create"} />
        <NavItem title={"My Listings"} path={"/account/listings"} />
        {/* <NavItem title={"Favorites"} path={"/account/favorites"} /> */}
        <NavItem title={"Settings"} path={"/account/settings"} />
      </div>
    </div>
  );
};

export default MiniNav;

const NavItem = ({ title, path }) => {
  const location = useLocation();
  return (
    <Link
      className="h-[40px] md:h-[50px] flex flex-col justify-between"
      to={path}
    >
      <p
        className="font-[700] text-[16px] md:text-[20px] tracking-[-0.02em] whitespace-nowrap px-[1rem] "
        style={{
          color: location.pathname == path ? "#537CD9" : "#6d6d6d",
          transition: "all 0.3s ease",
        }}
      >
        {title}
      </p>
      <div
        style={{
          background: location.pathname == path ? "#537CD9" : "transparent",
          transition: "all 0.3s ease",
        }}
        className="h-[3px] w-full bg-grey"
      ></div>
    </Link>
  );
};

import { useState } from "react";
import { EyeSvg, PhoneSvg } from "./svg";

const ViewNumber = ({ number, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={`w-full rounded-b-[10px] bg-[#Dee3eE] px-[30px] py-[15px] flex justify-between items-center ${className} `}
    >
      <div className="flex gap-[15px] items-center">
        <div className=" min-h-[50px] min-w-[50px] bg-[#fdfdfe] border-[1px] border-lightGrey rounded-full overflow-hidden flex items-center justify-center ">
          <PhoneSvg className="" />
        </div>
        {isOpen ? (
          <p className="font-[700] text-[20px] leading-[24px] truncate w-full tracking-[1.5px] ">
            <a href={`tel:${+2349016467730}`}>{number}</a>
          </p>
        ) : (
          <p className="font-[700] text-[20px] leading-[24px] truncate w-full tracking-[1.5px] ">
            {number.substring(0, 3)}
            <span className="text-orange">********</span>
          </p>
        )}
      </div>
      <div
        className=" h-[50px] w-[50px] bg-orange rounded-full overflow-hidden flex items-center justify-center cursor-pointer "
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        <EyeSvg className="" />
      </div>
    </div>
  );
};

export default ViewNumber;

import { Link } from "react-router-dom";
import whatsapp from "../../assets/img/whatsapp.png";

const ViewWhatsapp = ({ link, className }) => {
  return (
    <Link
      to={link}
      className={`block h-fit w-full  rounded-[10px] border-[#F2F4F8] border-[1px] mt-[2rem] px-[30px] py-[30px] flex items-center gap-[15px] ${className}`}
      target="_blank"
    >
      <img
        src={whatsapp}
        alt="whatsapp"
        className="w-[30px] h-[30px] transition-all duration-[450] ease-in-out m-0 "
      />
      <p className="font-[700] text-[18px] ">Chat via WhatsApp</p>
    </Link>
  );
};

export default ViewWhatsapp;

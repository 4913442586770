import { Box, Card, Text, HStack, Circle } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { PiBathtubLight } from "react-icons/pi";
import { IoBedOutline, IoCarSportOutline } from "react-icons/io5";

const PropertyCard = ({
  data,
  id,
  thumbnail,
  title,
  desc,
  amount,
  className,
}) => {
  return (
    <Card
      w={["95%", "45%", "", "", "280px"]}
      transition="all .3s ease-in-out"
      borderRadius="5px"
      overflow="hidden"
      cursor="pointer"
      _hover={{
        boxShadow:
          "0px 6px 20px 0px rgba(41.99999999999999, 56.99999999999999, 70, 0.08)",
      }}
      className={className}
    >
      <Link to={`/listing/${id}`}>
        <Box
          backgroundColor={"grey"}
          backgroundImage={thumbnail}
          h="200px"
          backgroundPosition="top"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          overflow="hidden"
        ></Box>
      </Link>
      <Box
        p="20px 15px"
        h="130px"
        fontSize="18px"
        fontWeight="600"
        color="text.main"
        lineHeight="20px"
      >
        <Text mb="10px">{title}</Text>
        <Text fontFamily="roboto" fontSize="18px">
          ₦{amount}{" "}
          {data?.offerType === "on-rent" &&
            data?.paymentDuration &&
            `/ ${
              data?.paymentDuration === "annum"
                ? "Year"
                : data?.paymentDuration === "monthly"
                ? "Month"
                : data?.paymentDuration === "weekly"
                ? "Week"
                : data?.paymentDuration === "daily"
                ? "Day"
                : data?.paymentDuration
            }`}
        </Text>
        <p className="py-3 text-sm font-medium">{desc}...</p>
      </Box>
      <HStack
        px="15px"
        gap="20px"
        borderTop="1px solid"
        py="20px"
        borderColor="base.border1"
      >
        <Link to={`/listing/${id}`}>
          <Circle
            border="1px solid"
            borderColor="base.border1"
            w="35px"
            h="35px"
            transition="all .3s ease-in-out"
            _hover={{ color: "base.primary" }}
            // className="flex flexcol"
          >
            <IoBedOutline />
            <p>{data?.bedrooms}</p>
          </Circle>
        </Link>
        <Circle
          border="1px solid"
          borderColor="base.border1"
          w="35px"
          h="35px"
          transition="all .3s ease-in-out"
          _hover={{ color: "base.primary" }}
        >
          <PiBathtubLight />
          <p>{data?.bathrooms}</p>
        </Circle>
        <Circle
          border="1px solid"
          borderColor="base.border1"
          w="35px"
          h="35px"
          transition="all .3s ease-in-out"
          _hover={{ color: "base.primary" }}
        >
          <IoCarSportOutline />
          <p>{data?.parkingspaces}</p>
        </Circle>
      </HStack>
    </Card>
  );
};

export default PropertyCard;

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Circle,
  HStack,
  Icon,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { FiUser } from "react-icons/fi";

import { zIndex } from "../../../styles/zIndex";
import DesktopMenu from "../../Menu/DesktopMenu/DesktopMenu";
import { PublicRoutes } from "../../../routes/Routes";
import { BiAddToQueue } from "react-icons/bi";
import { LiaClipboardListSolid } from "react-icons/lia";
import { LuHeart, LuSettings2 } from "react-icons/lu";
import { MdLogout } from "react-icons/md";
import { useAppStore } from "../../../store/store";

const DesktopHeader = () => {
  const userData = JSON.parse(localStorage.getItem("BRSUserData"));
  const setUserIsLoggedIn = useAppStore((state) => state.setUserIsLoggedIn);
  const isUserLoggedIn = useAppStore((state) => state.isUserLoggedIn);
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);
  const [option, setOption] = useState("");

  const menuRef = useRef();
  const menuOptionsRef = useRef();

  useEffect(() => {
    function handleMenuRef(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }

    function handleMenuOptionsRef(event) {
      if (
        menuOptionsRef.current &&
        menuOptionsRef.current.contains(event.target)
      ) {
        setShowMenu(true);
      }
    }

    document.addEventListener("mouseover", handleMenuRef);
    document.addEventListener("mouseover", handleMenuOptionsRef);

    return () => {
      document.removeEventListener("mouseover", handleMenuRef);
      document.removeEventListener("mouseover", handleMenuOptionsRef);
    };
  }, [menuRef]);

  return (
    <Box
      display={["none", "none", "none", "flex"]}
      pos="fixed"
      left="0"
      right="0"
      justifyContent="space-evenly"
      borderBottom="1px solid"
      borderColor="base.border3"
      height="79px"
      bg="base.white"
      zIndex={zIndex.MODAL}
      padding={["0px", "0px", "0px", "0 40px"]}
    >
      <HStack className="ml-20" justify="center">
        <ReactRouterLink to={"/"}>
          <Image src="/images/logo.png" h="70px" cursor="pointer" />
        </ReactRouterLink>
      </HStack>
      <HStack
        className=" "
        fontWeight="600"
        flex="1"
        justify="center"
        ref={menuRef}
      >
        <Button
          bg={option === "buy" && showMenu ? "base.border3" : ""}
          variant="nav"
          onMouseEnter={() => {
            setOption("buy");
            setShowMenu(true);
          }}
        >
          Buy
        </Button>
        <Button
          bg={option === "rent" && showMenu ? "base.border3" : ""}
          variant="nav"
          onMouseEnter={() => {
            setOption("rent");
            setShowMenu(true);
          }}
        >
          Rent
        </Button>
        <Button
          bg={option === "sell" && showMenu ? "base.border3" : ""}
          variant="nav"
          onMouseEnter={() => {
            setOption("sell");
            setShowMenu(true);
          }}
        >
          Sell
        </Button>
        <Button
          bg={option === "commercial" && showMenu ? "base.border3" : ""}
          variant="nav"
          onMouseEnter={() => {
            setOption("commercial");
            setShowMenu(true);
          }}
        >
          Commercial
        </Button>
        <Button
          bg={option === "advice" && showMenu ? "base.border3" : ""}
          variant="nav"
          onMouseEnter={() => {
            setOption("advice");
            setShowMenu(true);
          }}
        >
          Advise
        </Button>
        <Button
          bg={option === "about" && showMenu ? "base.border3" : ""}
          variant="nav"
          onMouseEnter={() => {
            setOption("about");
            setShowMenu(true);
          }}
        >
          About
        </Button>
      </HStack>
      <HStack flex="1" justify="center" gap="20px">
        <Button variant="primary" as={ReactRouterLink} to={PublicRoutes.PLANS}>
          Sell
        </Button>
        {!isUserLoggedIn && (
          <Button
            as={ReactRouterLink}
            to={PublicRoutes.LOGIN}
            variant="secondary"
          >
            Login
          </Button>
        )}
        {isUserLoggedIn && (
          <Popover>
            <PopoverTrigger>
              <Circle
                bg="blue.50"
                color="base.secondary"
                p="10px"
                cursor="pointer"
              >
                <Icon boxSize="1.2em" as={FiUser} />
              </Circle>
            </PopoverTrigger>
            <PopoverContent w="260px">
              <PopoverArrow />
              <PopoverBody p="0" py="0px" fontSize="18px">
                <HStack
                  cursor="pointer"
                  px="20px"
                  py="10px"
                  role="group"
                  onClick={() => {
                    navigate("/account/create");
                  }}
                >
                  <Icon
                    as={BiAddToQueue}
                    _groupHover={{ color: "base.primary" }}
                    transition="all .3s ease-out"
                  />
                  <Text
                    textStyle="menuLinkText"
                    _groupHover={{ color: "base.primary" }}
                  >
                    Add Listing
                  </Text>
                </HStack>
                <HStack
                  cursor="pointer"
                  px="20px"
                  py="10px"
                  role="group"
                  onClick={() => {
                    navigate("/account/listings");
                  }}
                >
                  <Icon
                    as={LiaClipboardListSolid}
                    _groupHover={{ color: "base.primary" }}
                    transition="all .3s ease-out"
                  />
                  <Text
                    textStyle="menuLinkText"
                    _groupHover={{ color: "base.primary" }}
                  >
                    My Listings
                  </Text>
                </HStack>
                {/* <HStack
                  cursor="pointer"
                  px="20px"
                  py="10px"
                  role="group"
                  onClick={() => {
                    navigate("/account/favorites");
                  }}
                >
                  <Icon
                    as={LuHeart}
                    _groupHover={{ color: "base.primary" }}
                    transition="all .3s ease-out"
                  />
                  <Text
                    textStyle="menuLinkText"
                    _groupHover={{ color: "base.primary" }}
                  >
                    Favorites
                  </Text>
                </HStack> */}
                <HStack
                  cursor="pointer"
                  px="20px"
                  py="10px"
                  role="group"
                  onClick={() => {
                    navigate("/account/settings");
                  }}
                >
                  <Icon
                    as={LuSettings2}
                    _groupHover={{ color: "base.primary" }}
                    transition="all .3s ease-out"
                  />
                  <Text
                    textStyle="menuLinkText"
                    _groupHover={{ color: "base.primary" }}
                  >
                    Settings
                  </Text>
                </HStack>
                <HStack
                  cursor="pointer"
                  px="20px"
                  py="10px"
                  role="group"
                  borderTop="1px solid"
                  borderColor="base.border1"
                  onClick={() => {
                    localStorage.removeItem("BRSUserData");
                    localStorage.removeItem("BRSUserToken");
                    setUserIsLoggedIn(false);
                    navigate("/");
                  }}
                >
                  <Icon
                    as={MdLogout}
                    _groupHover={{ color: "base.primary" }}
                    transition="all .3s ease-out"
                  />
                  <Text
                    textStyle="menuLinkText"
                    _groupHover={{ color: "base.primary" }}
                  >
                    Log out
                  </Text>
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </HStack>
      <DesktopMenu show={showMenu} compRef={menuOptionsRef} option={option} />
    </Box>
  );
};

export default DesktopHeader;

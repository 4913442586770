import axios from "axios";

const AxiosReq = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_SERVER,
});

AxiosReq.interceptors.request.use((res) => {
  const userToken = localStorage.getItem("BRSUserToken");
  const token = userToken ? JSON.parse(userToken) : "";
  res.headers.Authorization = `Bearer ${token}`;
  return res;
});

export const getPropertiesApi = async (searchString, pageNo, limit) => {
  return await AxiosReq.get(
    `/properties?${searchString}page=${pageNo}&limit=${limit}`
  );
};

export const addPropertyApi = async (values) => {
  return await AxiosReq.post(`/properties`, values);
};
export const getUserDataApi = async (id) => {
  return await AxiosReq.get(`/users/me/`);
};
export const getUserPropertiesApi = async (id, pageNo, limit) => {
  return await AxiosReq.get(
    `/users/${id}/properties?page=${pageNo}&limit=${limit}`
  );
};
export const getPropertyApi = async (id) => {
  return await AxiosReq.get(`/properties/${id}`);
};

export const updateUserApi = async (id, body) => {
  return await AxiosReq.patch(`/users/updateme`, body);
};
export const updateUserPasswordApi = async (body) => {
  return await AxiosReq.patch(`/users//updatemypassword`, body);
};

const CheckBox = ({
  id,
  name,
  text,
  value,
  disabled,
  amount,
  field,
  type,
  searchParams,
  setSearchParams,
}) => {
  return (
    <div
      className={`flex gap-[5px] items-center !cursor-pointer group/radio w-fit ${
        type === "big" && "!gap-[10px] "
      }`}
    >
      <input
        {...field}
        className={`cursor-pointer ${
          type === "big" && "w-[18px] h-[18px] rounded-[5px] "
        } `}
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={
          searchParams.propertyFeatures &&
          searchParams.propertyFeatures.filter((feature) => {
            return feature === value;
          }).length > 0
        }
        disabled={disabled}
        onChange={(e) => {
          if (searchParams) {
            setSearchParams((prev) => {
              const prevPropertyFeatures = prev.propertyFeatures;
              const valuesAlreadyExists = prevPropertyFeatures?.filter(
                (feature) => {
                  return feature === value;
                }
              );

              if (valuesAlreadyExists && valuesAlreadyExists.length > 0) {
                //if value already exists, remove from array
                const arrayWithoutValue = prevPropertyFeatures?.filter(
                  (feature) => {
                    return feature !== value;
                  }
                );
                return {
                  ...prev,
                  propertyFeatures: arrayWithoutValue,
                };
              } else {
                return {
                  ...prev,
                  propertyFeatures: prevPropertyFeatures
                    ? [...prevPropertyFeatures, value]
                    : [value],
                };
              }
            });
          }
        }}
      />
      <label
        className={`${
          disabled
            ? "!text-otherGrey"
            : "group-hover/radio:text-purple cursor-pointer "
        } transition-all leading-[1] ${type === "big" && "font-[600] "} `}
        for={id}
      >
        {text}
        {/* {amount && "(" + amount + ")"} */}
      </label>
    </div>
  );
};

export default CheckBox;

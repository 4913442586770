import { useEffect, useState } from "react";
import { propertyFeaturesCheckBoxData } from "../../helper/data";
import { featuredListings } from "../../mock/featuredData";
import PropertyCard from "../../components/atoms/PropertyCard";
import { CloseSvg } from "../../components/atoms/svg";
import { useQuery } from "@tanstack/react-query";
import CheckBox from "../../components/atoms/CheckBox";
import { getPropertiesApi } from "../../services/api";
import { useLocation } from "react-router-dom";
import { getSearchParams } from "../../helper/helpers";
import { SlArrowRightCircle } from "react-icons/sl";
import Pagination from "../../components/atoms/Pagination";
import RadioBtn from "../../components/atoms/RadioBtn";
import { IoClose } from "react-icons/io5";

const ViewListings = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setWindowWidth(window.innerWidth);
    });

    return window.removeEventListener("resize", function () {
      setWindowWidth(window.innerWidth);
    });
  }, []);
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 9;
  const [searchParams, setSearchParams] = useState({
    offerType: "",
    propertyType: "",
    priceMin: 0,
    priceMax: 0,
    bedrooms: 0,
    propertyFeatures: null,
    ...location.state,
  });

  const [searchString, setSearchString] = useState(
    getSearchParams(searchParams)
  );

  const [propertyFeatures, setPropertyFeatures] = useState(
    propertyFeaturesCheckBoxData
  );
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [minPrice, setMinPrice] = useState(searchParams?.priceMin);
  const [maxPrice, setMaxPrice] = useState(searchParams?.priceMax);

  const { isLoading, error, data } = useQuery({
    queryKey: ["getProperties", searchString, page],
    queryFn: async (searchStringObj) => {
      let pageNo = searchStringObj?.queryKey[2];
      const res = await getPropertiesApi(
        searchStringObj.queryKey[1],
        pageNo,
        limit
      );
      setTotalPages(res?.data?.totalPages);
      return res?.data;
    },
  });

  useEffect(() => {
    setSearchString(getSearchParams(searchParams));
    setPage(1);
  }, [searchParams]);

  useEffect(() => {
    const setData = setTimeout(() => {
      setSearchParams((prev) => {
        return { ...prev, priceMin: Number(minPrice) };
      });
    }, 1000);

    return () => clearTimeout(setData);
  }, [minPrice]);

  useEffect(() => {
    const setData = setTimeout(() => {
      setSearchParams((prev) => {
        return { ...prev, priceMax: Number(maxPrice) };
      });
    }, 1000);

    return () => clearTimeout(setData);
  }, [maxPrice]);

  // console.log(location.state);
  // console.log(searchParams);

  return (
    <div className="flex pt-[120px] pb-[50px] max-md:!px-[20px] px-[50px] container m-auto gap-[30px] max-w-[1300px] relative ">
      <div
        style={{
          transform:
            !isFilterMenuOpen && windowWidth < 700
              ? "translateX(-100%)"
              : "none",
        }}
        className={`max-lg:translate-x-${
          !isFilterMenuOpen && "[-100%]"
        } max-md:fixed max-md:top-0 max-md:left-0 max-md:z-[1000] max-md:h-screen relative transition-all ease duration-[300ms]`}
      >
        <div className="flex flex-col w-[270px] max-md:h-screen rounded-[5px] border-[1px] border-lightGrey bg-[#Fdfdfe] p-[2rem] pt-[2.5rem] max-md:overflow-y-scroll md:sticky md:top-[100px]">
          <div
            className="max-md:flex hidden w-[50px] h-[50px] bg-white border-[1px] rounded-full justify-center items-center cursor-pointer transition-all ease duration-[150ms] active:scale-[0.8] mt-[-1rem] mb-[1rem]"
            onClick={() => setIsFilterMenuOpen(false)}
          >
            <CloseSvg className="h-[40px] w-[40px] " />
          </div>

          {searchParams?.address?.length > 0 && (
            <>
              <div className="flex items-center justify-between w-full">
                <h5 className="text-[#314352] font-[700] text-[20px] ">
                  Places
                </h5>
                <div className="bg-[#d5e3ee] h-[10px] w-[10px] rounded-full "></div>
              </div>
              <div className="w-full flex flex-col gap-[3px] mb-[2rem]">
                {searchParams?.address.map((place, index) => {
                  return (
                    <div className="h-fit w-full bg-[#537cd9] text-white font-[500] text-start flex items-center justify-between gap-2 p-2 rounded-[5px] ">
                      <p className="whitespace-nowrap truncate text-[12px]">
                        {place}
                      </p>
                      <IoClose
                        className="cursor-pointer !min-h-[15px] !min-w-[15px] "
                        onClick={() => {
                          const filteredSelected = searchParams?.address.filter(
                            (filterdatum) => {
                              return filterdatum !== place;
                            }
                          );
                          setSearchParams({
                            ...searchParams,
                            address: filteredSelected,
                          });
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <div className="flex items-center justify-between w-full ">
            <h5 className="text-[#314352] font-[700] text-[20px] ">
              Offer Type
            </h5>
            <div className="bg-[#d5e3ee] h-[10px] w-[10px] rounded-full "></div>
          </div>
          <div className="offerType flex flex-col gap-[5px] pt-[1rem] text-[16px]">
            <RadioBtn
              id={"All"}
              name={"offerType"}
              text={"All"}
              value={""}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
            <RadioBtn
              id={"Rent"}
              name={"offerType"}
              text={"Rent"}
              value={"on-rent"}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
            <RadioBtn
              id={"Sell"}
              name={"offerType"}
              text={"Sell"}
              value={"on-sale"}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
            {/* <RadioBtn
              id={"Lease"}
              name={"offerType"}
              text={"Lease (0)"}
              value={"on-lease"}
              // disabled={true}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            /> */}
          </div>

          <div className="flex items-center justify-between w-full mt-[2rem]">
            <h5 className="text-[#314352] font-[700] text-[20px] ">
              Property Type
            </h5>
            <div className="bg-[#d5e3ee] h-[10px] w-[10px] rounded-full "></div>
          </div>
          <div className="propertyType flex flex-col gap-[5px] pt-[1rem] text-[16px]">
            <RadioBtn
              id={"all"}
              name={"type"}
              text={"All"}
              value={""}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
            <RadioBtn
              id={"apartments"}
              name={"type"}
              text={"Apartments/Flats"}
              value={"apartments"}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />

            <RadioBtn
              id={"house"}
              name={"type"}
              text={"House"}
              value={"house"}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
            <RadioBtn
              id={"CommercialProperty"}
              name={"type"}
              text={"Commercial Property"}
              value={"commercial"}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
            <RadioBtn
              id={"IndustrialProperty"}
              name={"type"}
              text={"Industrial Property"}
              value={"industrial"}
              // disabled={true}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
            <RadioBtn
              id={"Land/Plots"}
              name={"type"}
              text={"Land/Plots"}
              value={"land"}
              // disabled={true}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
            <RadioBtn
              id={"Retirement"}
              name={"type"}
              text={"Retirement"}
              value={"retirement"}
              // disabled={true}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </div>

          <div className="flex items-center justify-between w-full mt-[2rem]">
            <h5 className="text-[#314352] font-[700] text-[20px] ">Price</h5>
            <div className="bg-[#d5e3ee] h-[10px] w-[10px] rounded-full "></div>
          </div>
          <div className="pt-[1rem] flex flex-col gap-[10px]">
            <input
              type="number"
              value={minPrice}
              className="py-[15px] px-[20px] appearance-none border-otherGrey border-[1px] text-[18px] font-[700] rounded-[5px] w-full focus:border-purple"
              placeholder="Min Price"
              min={0}
              onChange={(e) => setMinPrice(e.target.value)}
            />
            <input
              type="number"
              value={maxPrice}
              className="py-[15px] px-[20px] appearance-none border-otherGrey border-[1px] text-[18px] font-[700] rounded-[5px] w-full "
              placeholder="Max Price"
              min={minPrice}
              onChange={(e) => setMaxPrice(e.target.value)}
            />
          </div>

          <div className="flex items-center justify-between w-full mt-[2rem]">
            <h5 className="text-[#314352] font-[700] text-[20px] ">Bedrooms</h5>
            <div className="bg-[#d5e3ee] h-[10px] w-[10px] rounded-full "></div>
          </div>
          <select
            value={searchParams?.bedrooms}
            onChange={(e) => {
              setSearchParams((prev) => {
                return { ...prev, bedrooms: Number(e.target.value) };
              });
            }}
            variant="outline"
            placeholder="Outline"
            className="mt-[1rem] py-[15px] px-[20px] h-fit appearance-none border-otherGrey border-[1px] text-[18px] font-[700] rounded-[5px] w-full"
          >
            <option value="0">0+</option>
            <option value="1">1+</option>
            <option value="2">2+</option>
            <option value="3">3+</option>
            <option value="4">4+</option>
            <option value="5">5+</option>
          </select>

          <div className="flex items-center justify-between w-full mt-[2rem]">
            <h5 className="text-[#314352] font-[700] text-[20px] ">
              Property Features
            </h5>
            <div className="bg-[#d5e3ee] h-[10px] w-[10px] rounded-full "></div>
          </div>
          <div className="pt-[1rem] flex flex-col gap-[10px]">
            <input
              type="search"
              className="py-[15px] px-[20px] appearance-none border-otherGrey border-[1px] text-[14px] font-[700] rounded-[5px] w-full focus:outline-none"
              placeholder="Search Features"
              onChange={(e) => {
                const filteredList = propertyFeaturesCheckBoxData.filter(
                  (value) => {
                    return value?.name
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  }
                );
                setPropertyFeatures(filteredList);
              }}
            />
            <div className="h-[170px] overflow-y-scroll flex flex-col gap-[10px] ">
              {propertyFeatures.map((datum) => {
                return (
                  <CheckBox
                    id={datum.name}
                    name={datum.name}
                    text={datum.value}
                    value={datum.name}
                    amount={datum.available}
                    disabled={false}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                  />
                );
              })}
              {propertyFeatures.length < 1 && (
                <p className="text-grey">No property with features available</p>
              )}
            </div>
          </div>
        </div>
        <div
          className="max-md:flex hidden absolute w-[40px] h-[60px] flex items-center justify-center translate-x-[40px] bg-white rounded-r-[20px] shadow right-0 top-0 bottom-0 m-auto cursor-pointer"
          onClick={() => setIsFilterMenuOpen((prev) => !prev)}
        >
          <SlArrowRightCircle
            style={{
              transform: isFilterMenuOpen ? "rotate(-180deg)" : "rotate(0deg)",
            }}
            className="text-red w-[25px] h-[25px] font-[500] transition-all ease duration-[500ms] "
          />
        </div>
      </div>

      <div className="flex-1">
        <div className="flex items-center justify-between max-[800px]:flex-col gap-[1rem]">
          <h1 className="text-[24px] text-[#314352] font-[700] ">
            {data?.results || 0} Results{" "}
            <span className="pl-2 text-[16px] text-purple font-[700]">
              Real Estate
            </span>
          </h1>
          <div className="w-fit">
            <div className="flex items-center gap-[1rem] w-full">
              <p className="text-[16px] text-[#314352] font-[700] whitespace-nowrap">
                Sort by:
              </p>
              <select
                variant="outline"
                placeholder="Outline"
                className=" py-[15px] px-[20px] h-fit appearance-none border-otherGrey border-[1px] text-[18px] font-[700] rounded-[5px] w-full min-w-[200px] max-w-[300px]"
              >
                <option value="mostRelevant">Most Relevant</option>
                <option value="dateNewest">Date Listed: Newest</option>
                <option value="dateOldest">Date Listed: Oldest</option>
                <option value="priceNewest">Price Newest</option>
                <option value="priceOldest">Price Oldest</option>
              </select>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center h-[80vh]">
            <p>Loading...</p>
          </div>
        ) : data?.data.length === 0 ? (
          <div className="flex items-center justify-center h-[80vh]">
            <p>No data</p>
          </div>
        ) : error ? (
          <div className="flex items-center justify-center h-[80vh]">
            <p className="text-red">An Error Occurred...</p>
          </div>
        ) : (
          <div className="min-h-[50vh]">
            <div className="mt-[1rem] grid grid-cols-3 max-lg:grid-cols-2 max-[766px]:grid-cols-1 gap-[1rem] w-full justify-items-center ">
              {data?.data?.map((datum) => {
                return (
                  <PropertyCard
                    data={datum}
                    id={datum?._id}
                    thumbnail={datum?.images[0]}
                    title={datum?.title}
                    amount={datum?.price?.toLocaleString() || 0}
                    className={
                      "!w-full max-[766px]:!max-w-[380px] max-h-[400px]"
                    }
                  />
                );
              })}
            </div>
            <div className="my-6 ">
              <Pagination
                currentNo={page}
                setCurrentNo={setPage}
                totalNo={totalPages}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewListings;

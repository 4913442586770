export const featuredListings = [
  {
    id: "65f39e33c4b34e77a8e34982",
    title: "Fully detached 6 bedroom for sale in Lekki",
    amount: "3,000,000",
    thumbnail:
      "https://bsrproperty365.com/wp-content/uploads/2023/12/asokoro-detached-house-6438-1-720x450.jpg",
  },
  {
    id: "65f39e33c4b34e77a8e34982",
    title: "Studio Apartment for sale in Maitama",
    amount: "3,000,000",
    thumbnail:
      "https://bsrproperty365.com/wp-content/uploads/2022/10/single-listing_3-720x640.jpg",
  },
  {
    id: "65f39e33c4b34e77a8e34982",
    title: "2 Bedroom Flat for rent",
    amount: "208,000 / month",
    thumbnail:
      "https://bsrproperty365.com/wp-content/uploads/2023/11/IMG_3208.jpeg",
  },
  {
    id: "65f39e33c4b34e77a8e34982",
    title: "6 Bedroom in Garki for rent",
    amount: "700,000 / month",
    thumbnail:
      "https://bsrproperty365.com/wp-content/uploads/2022/05/post_6-720x640.jpg",
  },
];

import { useRoutes } from "react-router-dom";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";
import { useQuery } from "@tanstack/react-query";
import { getUserDataApi } from "../services/api";
import { useAppStore } from "../store/store";
import { useEffect } from "react";
import { adminProtectedRoutes } from "./admin";

const AppRouter = () => {
  const userData = JSON.parse(localStorage.getItem("BRSUserData"));
  const getUserData = useAppStore((state) => state.userData);
  const setUserIsLoggedIn = useAppStore((state) => state.setUserIsLoggedIn);
  const setUserData = useAppStore((state) => state.setUserData);
  const setUserIsAdmin = useAppStore((state) => state.setUserIsAdmin);

  const { isLoading, error, data } = useQuery({
    queryKey: ["getUserData", userData?.data?.user?._id],
    queryFn: async (idObj) => {
      if (userData) {
        setUserIsLoggedIn(true);
        const res = await getUserDataApi(idObj.queryKey[1]);
        localStorage.setItem("BRSUserData", JSON.stringify(res?.data?.user));
        if (res?.data?.user.role === "admin") {
          setUserIsAdmin(true);
        }
        setUserData(res?.data?.user);
        return res?.data?.user;
      }
      return null;
    },
  });

  useEffect(() => {
    if (userData) {
      setUserData(userData);
    }
  }, []);

  useEffect(() => {
    if (userData?.role === "admin") {
      setUserIsAdmin(true);
    }
  }, []);

  const routes = useRoutes([
    ...adminProtectedRoutes,
    ...protectedRoutes,
    ...publicRoutes,
  ]);
  return routes;
};

export default AppRouter;

import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const NewListings = ({ id, thumbnail, title, amount }) => {
  return (
    <Link to={`/listing/${id}`}>
      <HStack role="group" cursor="pointer" my="10px">
        <Box
          overflow="hidden"
          w="50px"
          h="40px"
          borderRadius="6px"
          bg="grey"
          backgroundImage={thumbnail}
          backgroundPosition="center"
          backgroundSize="cover"
        ></Box>
        <VStack align="flex-start" gap="0">
          <Text
            _groupHover={{ color: "base.primary" }}
            transition="all .3s ease-out"
          >
            {title}
          </Text>
          <Text>{amount}</Text>
        </VStack>
      </HStack>
    </Link>
  );
};

export default NewListings;

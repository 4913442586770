export const propertyFeaturesCheckBoxData = [
  {
    name: "AirConditioning",
    value: "Air conditioning",
    available: 2,
  },
  {
    name: "Balcony",
    value: "Balcony",
    available: 3,
  },
  {
    name: "BroadbandInternet",
    value: "Broadband Internet",
    available: 1,
  },
  {
    name: "CeilingFan",
    value: "Ceiling Fan",
    available: 2,
  },
  {
    name: "CentralHeating",
    value: "Central heating",
    available: 1,
  },
  {
    name: "Dryer",
    value: "Dryer",
    available: 1,
  },
  {
    name: "Furnished",
    value: "Furnished",
    available: 1,
  },
  {
    name: "Kitchen",
    value: "Kitchen",
    available: 1,
  },
  {
    name: "Laundry",
    value: "Laundry",
    available: 1,
  },
  {
    name: "RecreationalProperty",
    value: "Recreational Property",
    available: 1,
  },
  {
    name: "Refrigerator",
    value: "Refrigerator",
    available: 2,
  },
  {
    name: "Washer",
    value: "Washer",
    available: 1,
  },
  {
    name: "WiFi",
    value: "WiFi",
    available: 2,
  },
];

export const nigeriaStates = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];

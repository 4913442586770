import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { PiCaretDownThin } from "react-icons/pi";
import { MdOutlineSell } from "react-icons/md";

import { Link } from "react-router-dom";

const SellItem = () => {
  return (
    <AccordionItem border="none">
      <AccordionButton
        h="60px"
        color="text.main"
        borderBottom="1px solid"
        borderColor="base.faint"
        _hover={{ bg: "inherit" }}
      >
        <Box
          as="span"
          flex="1"
          textAlign="left"
          fontWeight="500"
          fontSize="18px"
          className="flex"
        >
          <MdOutlineSell className="mx-2 mt-1 text-amber-600" />
          Sell
        </Box>
        <AccordionIcon as={PiCaretDownThin} color="text.main" />
      </AccordionButton>
      <AccordionPanel px="0" py="0">
        <Link to={"/account/create"}>
          <Box
            h="60px"
            w="100%"
            pl="30px"
            color="text.main"
            borderBottom="1px solid"
            borderColor="base.faint"
            fontSize="18px"
            display="flex"
            alignItems="center"
            fontWeight="500"
            cursor="pointer"
          >
            Sell Your Property
          </Box>
        </Link>
        <Link to={"/account/create"}>
          <Box
            h="60px"
            w="100%"
            pl="30px"
            color="text.main"
            borderBottom="1px solid"
            borderColor="base.faint"
            fontSize="18px"
            display="flex"
            alignItems="center"
            fontWeight="500"
            cursor="pointer"
          >
            List Your Property
          </Box>
        </Link>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default SellItem;

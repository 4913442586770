import { useLocation } from "react-router-dom";
import sky from "../../assets/img/sky.webp";
import woman from "../../assets/img/wowo.webp";
import PropertyCard from "../../components/atoms/PropertyCard";
import ViewNumber from "../../components/atoms/ViewNumber";
import ViewWhatsapp from "../../components/atoms/ViewWhatsapp";
import { ProfileSvg } from "../../components/atoms/svg";
import { featuredListings } from "../../mock/featuredData";
import { useEffect, useState } from "react";
import { capFirstLetter } from "../../helper/helpers";
import { getUserPropertiesApi } from "../../services/api";
import { useQuery } from "@tanstack/react-query";

const ViewUserProfile = () => {
  const [agentData, setAgentData] = useState();
  const location = useLocation();
  useEffect(() => {
    setAgentData(location?.state);
  }, [location]);

  const { isLoading, error, data } = useQuery({
    queryKey: ["getAgentListedProperties", agentData],
    queryFn: async (queryK) => {
      const res = await getUserPropertiesApi(agentData?._id);
      return res?.data;
    },
    enabled: !!agentData?._id,
  });

  return (
    <>
      <div className="flex flex-col pt-[79px] pb-[50px] max-md:!px-[20px] px-[50px] container m-auto  max-w-[1300px]">
        <div className="w-full rounded-b-[10px] h-[200px] overflow-hidden">
          <img
            src={sky}
            alt="back"
            className="w-full h-full object-cover brightness-[0.8]"
          />
        </div>

        <div className="flex items-center justify-center">
          <div className="w-[220px] h-[220px] overflow-hidden rounded-full object-cover shadow mt-[-110px] z-[2]">
            <img
              src={
                agentData?.photo === "default.jpg" ? woman : agentData?.photo
              }
              alt="back"
              className="object-cover w-full h-full rounded-full "
            />
          </div>
        </div>

        <div className="flex items-center justify-center mt-[30px]">
          <h1 className="font-[800] text-[40px] ">
            {capFirstLetter(agentData?.name.split(" ")[0] || "") +
              " " +
              capFirstLetter(agentData?.name.split(" ")[1] || "")}
          </h1>
        </div>

        <div className="flex items-center justify-center mt-[15px]">
          <div className="flex gap-[10px] items-center">
            <div className="bg-orange w-[20px] h-[20px] flex flex-row items-center justify-center rounded-full ">
              <ProfileSvg className="" />
            </div>
            <p className="text-[#73818c] font-[500] ">Member since 4 months</p>
          </div>
        </div>

        <div className="flex max-[700px]:flex-col items-center justify-center gap-x-[2rem] gap-y-[1rem] mt-[30px] ">
          <ViewNumber
            number={String(agentData?.phoneNum)}
            className={
              "!bg-white border-[1px] border-lightGrey !rounded-[10px] !max-w-[360px] "
            }
          />
          {agentData?.isWhatsapp && (
            <ViewWhatsapp
              link={`https://api.whatsapp.com/send?phone=${String(
                agentData?.phoneNum
              ).replace("+", "")}`}
              className={
                "!bg-white border-[1px] border-lightGrey !rounded-[10px] !max-w-[360px] !h-[82px] !py-[15px] !mt-0"
              }
            />
          )}
        </div>
      </div>
      <div className="bg-[#F8FAFD] w-full py-[3rem] ">
        <div className="flex flex-col max-md:!px-[20px] px-[50px] container m-auto  max-w-[1300px]">
          <div className="flex items-center justify-center gap-[20px] mt-[1rem]">
            <h1 className="font-[800] text-[40px] ">User Listings</h1>
            <div className="bg-purple flex items-center justify-center rounded-full px-[12px] ">
              <h1 className="font-[800] text-[20px] text-white ">
                {data?.results}
              </h1>
            </div>
          </div>

          <div className="mt-[3rem] grid grid-cols-3 max-lg:grid-cols-2 max-[766px]:grid-cols-1 gap-[1.5rem] w-full justify-items-center">
            {data?.data?.map((datum, index) => {
              return (
                <PropertyCard
                  data={datum}
                  key={index}
                  id={datum?._id}
                  thumbnail={datum?.images[0]}
                  title={datum?.title}
                  amount={datum?.price?.toLocaleString() || 0}
                  className={"!w-full max-[766px]:!max-w-[380px] max-h-[400px]"}
                />
              );
            })}
          </div>
          {/* <div className="mt-[3rem] flex gap-[5px] font-[700] text-[18px] text-[#73818c] ">
            <span>Showing</span>
            <span className="text-[#314352] ">1</span>
            <span>to</span>
            <span className="text-[#314352] ">4</span>
            <span>of</span>
            <span className="text-[#314352] ">4</span>
            <span>results</span>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ViewUserProfile;

import {
  Box,
  Button,
  Center,
  Circle,
  HStack,
  Icon,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { IoIosMenu } from "react-icons/io";
import { LiaClipboardListSolid, LiaUserAltSolid } from "react-icons/lia";
import MobileMenu from "../../Menu/MobileMenu/MobileMenu";
import { useBreakpoint } from "../../../hooks/utils/useBreakpoint";
import { useAppStore } from "../../../store/store";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { PublicRoutes } from "../../../routes/Routes";
import { BiAddToQueue } from "react-icons/bi";
import { LuHeart, LuSettings2 } from "react-icons/lu";
import { MdLogout } from "react-icons/md";
import { FiUser } from "react-icons/fi";

const MobileHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const setUserIsLoggedIn = useAppStore((state) => state.setUserIsLoggedIn);
  const isUserLoggedIn = useAppStore((state) => state.isUserLoggedIn);
  const navigate = useNavigate();
  const isNotMobile = useBreakpoint("xl");

  return (
    <Box>
      {!isNotMobile && <MobileMenu isOpen={isOpen} onClose={onClose} />}
      <Box
        display={["flex", "flex", "flex", "none"]}
        alignItems="center"
        justifyContent="space-between"
        padding={["7px 10px", "7px 20px"]}
        className="fixed w-full z-[1000] bg-white"
      >
        <Center
          w="50px"
          h="50px"
          borderRadius="50%"
          border="1px solid"
          borderColor="base.border1"
        >
          <Icon boxSize="1.7em" as={IoIosMenu} onClick={onOpen} />
        </Center>
        <Box>
          <ReactRouterLink to={"/"}>
            <Image src="/images/logo.png" h="70px" cursor="pointer" />
          </ReactRouterLink>
        </Box>
        {!isUserLoggedIn && (
          <Button
            as={ReactRouterLink}
            to={PublicRoutes.LOGIN}
            variant="secondary"
          >
            Login
          </Button>
        )}
        {isUserLoggedIn && (
          <Popover>
            <PopoverTrigger>
              <Circle
                bg="blue.50"
                color="base.secondary"
                p="10px"
                cursor="pointer"
              >
                <Icon boxSize="1.2em" as={FiUser} />
              </Circle>
            </PopoverTrigger>
            <PopoverContent w="260px">
              <PopoverArrow />
              <PopoverBody p="0" py="0px" fontSize="18px">
                <HStack
                  cursor="pointer"
                  px="20px"
                  py="10px"
                  role="group"
                  onClick={() => {
                    navigate("/account/create");
                  }}
                >
                  <Icon
                    as={BiAddToQueue}
                    _groupHover={{ color: "base.primary" }}
                    transition="all .3s ease-out"
                  />
                  <Text
                    textStyle="menuLinkText"
                    _groupHover={{ color: "base.primary" }}
                  >
                    Add Listing
                  </Text>
                </HStack>
                <HStack
                  cursor="pointer"
                  px="20px"
                  py="10px"
                  role="group"
                  onClick={() => {
                    navigate("/account/listings");
                  }}
                >
                  <Icon
                    as={LiaClipboardListSolid}
                    _groupHover={{ color: "base.primary" }}
                    transition="all .3s ease-out"
                  />
                  <Text
                    textStyle="menuLinkText"
                    _groupHover={{ color: "base.primary" }}
                  >
                    My Listings
                  </Text>
                </HStack>
                {/* <HStack
                  cursor="pointer"
                  px="20px"
                  py="10px"
                  role="group"
                  onClick={() => {
                    navigate("/account/favorites");
                  }}
                >
                  <Icon
                    as={LuHeart}
                    _groupHover={{ color: "base.primary" }}
                    transition="all .3s ease-out"
                  />
                  <Text
                    textStyle="menuLinkText"
                    _groupHover={{ color: "base.primary" }}
                  >
                    Favorites
                  </Text>
                </HStack> */}
                <HStack
                  cursor="pointer"
                  px="20px"
                  py="10px"
                  role="group"
                  onClick={() => {
                    navigate("/account/settings");
                  }}
                >
                  <Icon
                    as={LuSettings2}
                    _groupHover={{ color: "base.primary" }}
                    transition="all .3s ease-out"
                  />
                  <Text
                    textStyle="menuLinkText"
                    _groupHover={{ color: "base.primary" }}
                  >
                    Settings
                  </Text>
                </HStack>
                <HStack
                  cursor="pointer"
                  px="20px"
                  py="10px"
                  role="group"
                  borderTop="1px solid"
                  borderColor="base.border1"
                  onClick={() => {
                    localStorage.removeItem("BRSUserData");
                    localStorage.removeItem("BRSUserToken");
                    setUserIsLoggedIn(false);
                    navigate("/");
                  }}
                >
                  <Icon
                    as={MdLogout}
                    _groupHover={{ color: "base.primary" }}
                    transition="all .3s ease-out"
                  />
                  <Text
                    textStyle="menuLinkText"
                    _groupHover={{ color: "base.primary" }}
                  >
                    Log out
                  </Text>
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Box>
    </Box>
  );
};

export default MobileHeader;

import { Text } from "@chakra-ui/react";
import React, { Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import MiniNav from "../components/Menu/MiniNav/MiniNav";
import { useAppStore } from "../store/store";
import { AdminRoutes, ProtectedRoutes, PublicRoutes } from "./Routes";

const Listings = React.lazy(() => import("../pages/in-app/Listings"));
const Favorites = React.lazy(() => import("../pages/in-app/Favorites"));
const Settings = React.lazy(() => import("../pages/in-app/Settings"));
const CreateProperty = React.lazy(() =>
  import("../pages/in-app/CreateProperty")
);

const AuthRouter = () => {
  const isAuth = JSON.parse(localStorage.getItem("BRSUserToken"));
  const isUserAdmin = useAppStore((state) => state.isUserAdmin);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      navigate(PublicRoutes.LOGIN);
    } else if (isUserAdmin) {
      navigate(AdminRoutes.USERS);
    }
  });

  return (
    <Suspense fallback={<Text>Loading...</Text>}>
      <div className="flex flex-col h-full">
        <MiniNav />
        <Outlet />
      </div>
    </Suspense>
  );
};

export const protectedRoutes = [
  {
    path: ProtectedRoutes.BASE,
    element: <AuthRouter />,
    children: [
      {
        path: ProtectedRoutes.CREATE,
        name: "Create",
        element: <CreateProperty />,
      },
      {
        path: ProtectedRoutes.LISTINGS,
        name: "Listings",
        element: <Listings />,
      },
      {
        path: ProtectedRoutes.FAVORITES,
        name: "Favorites",
        element: <Favorites />,
      },
      {
        path: ProtectedRoutes.SETTINGS,
        name: "Settings",
        element: <Settings />,
      },
    ],
  },
];

import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
import { textStyles } from "./textStyles";
import { breakpoints } from "./breakpoints";
import { buttonStyles } from "../components/buttonStyles";
import { checkboxStyles } from "../components/checkboxStyles";

export const theme = extendTheme({
  breakpoints,
  textStyles,
  colors,
  components: {
    Button: buttonStyles,
    Checkbox: checkboxStyles,
  },
});

export const textStyles = {
  nav: {
    color: "text.main",
    fontWeight: "600",
    lineHeight: "79px",
  },
  linkText: {
    color: ["bg.white", "bg.white", "bg.white", "text.main"],
    transition: ".3s ease",
    cursor: "pointer",
    display: "inline-block",
    position: "relative",
    _after: {
      content: "''",
      position: "absolute",
      width: "100%",
      transform: "scaleX(0)",
      height: "1.5px",
      bottom: "0",
      left: "0",
      backgroundColor: "base.secondary",
      transformOrigin: "bottom right",
      transition: "transform 0.25s ease-out",
    },
    _hover: {
      color: "base.secondary",
      _after: {
        transform: "scaleX(1)",
        transformOrigin: "bottom left",
      },
    },
  },
  item: {
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "capitalize",
    // fontFamily: "Roboto",
  },
  selected: {
    fontSize: "12px",
    color: "base.secondary",
    textTransform: "capitalize",
    marginTop: "5px",
  },
  tag: {
    bg: "blue.100",
    w: "fit-content",
    borderRadius: "6px",
    p: "2px 7px",
    fontSize: "12px",
    color: "base.white",
    pos: "absolute",
    bottom: "10px",
    left: "10px",
    opacity: ".9",
    textTransform: "capitalize",
  },
  title: {
    fontSize: "18px",
    fontWeight: "600",
    mt: "10px",
    transition: "all .3s ease-out",
  },
  subtitle: {
    fontSize: "15px",
    mb: "6px",
    fontWeight: "400",
  },
  date: {
    fontSize: "12px",
    color: "gray.100",
    fontWeight: "600",
    mb: "31px",
  },
  footerLinkText: {
    transition: "all .3s ease-out",
    color: "base.white",
    cursor: "pointer",
    _hover: {
      color: "base.primary",
    },
  },
  menuLinkText: {
    transition: "all .3s ease-out",
    color: "text.main",
    cursor: "pointer",
    _hover: {
      color: "base.primary",
    },
  },
};

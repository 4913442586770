export const getSearchParams = (paramsObj) => {
  let searchStringInit = "";
  Object.keys(paramsObj).forEach((key) => {
    const value = paramsObj[key];

    //check ifs a truthy value or its an array and its not empty
    if (
      (!Array.isArray(value) && value) ||
      (Array.isArray(value) && value.length > 0)
    ) {
      searchStringInit += `${key}=${value}&`;
    }
  });

  return searchStringInit;
};

export const capFirstLetter = (word) => {
  const firstLetter = String(word).charAt(0).toUpperCase();
  const restLetters = String(word).slice(1);
  return firstLetter + restLetters;
};

export const truncateText = (text, wordCount) => {
  const words = text.split(/\s+/);
  if (words.length <= wordCount) {
    return text;
  }
  return `${words.slice(0, wordCount).join(" ")}...`;
};

import { CheckSvg } from "./svg";

const Tag = ({ type, svg, text, color, value }) => {
  return type === "big" ? (
    <div
      className={` flex items-center py-[3px] px-[10px] text-[16px] gap-[5px] border-[1px] rounded-[5px] border-[${
        color ? color : "#000"
      }] w-fit `}
      style={{ borderColor: color && color }}
    >
      {svg}
      <p style={{ color: color && color }} className={` font-[600]`}>
        {text}
      </p>
    </div>
  ) : type === "small" ? (
    <div className="bg-[#f2f4f8] rounded-[5px] flex gap-[2px] py-[3px] px-[10px] w-fit ">
      <p className={` font-[600]`}>{text}:</p>
      <p className={` font-[600]`}>{value}</p>
    </div>
  ) : (
    <div className="flex flex-1 w-full min-w-[47%] max-w-[50%] gap-[3px] items-center">
      <div className="bg-purple rounded-full w-[20px] h-[20px] flex items-center justify-center ">
        <CheckSvg className="" />
      </div>
      <p style={{ color: color && color }} className={` font-[600]`}>
        {text}
      </p>
    </div>
  );
};

export default Tag;

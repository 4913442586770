import React from "react";
import { Box, Circle, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { GiSmartphone } from "react-icons/gi";
import { LiaMapMarkerAltSolid } from "react-icons/lia";
import { MdAlternateEmail } from "react-icons/md";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { featuredListings } from "../../../mock/featuredData";
import NewListings from "../../atoms/NewListings";
import { useQuery } from "@tanstack/react-query";
import { getPropertiesApi } from "../../../services/api";

const Footer = () => {
  const { isLoading, error, data } = useQuery({
    queryKey: ["getPropertiesForFooter"],
    queryFn: async () => {
      const res = await getPropertiesApi("", 2, 4);
      return res?.data;
    },
  });
  return (
    <Box>
      <HStack
        bg="text.main"
        color="base.white"
        p={["50px 20px", "", "50px 50px", "", "80px 100px"]}
        flexDir={["column", "row"]}
        flexWrap="wrap"
        align="flex-start"
        gap={["80px", "0"]}
      >
        <VStack flex="1" align="flex-start" alignSelf="stretch">
          <HStack
            gap="2px"
            fontWeight="900"
            fontSize="22px"
            mb="30px"
            cursor="pointer"
            role="group"
          >
            <Text>BSR</Text>
            <Text
              textDecor="underline"
              _groupHover={{ color: "base.primary" }}
              transition="all .3s ease-out"
            >
              PROPERTY
            </Text>
          </HStack>
          <HStack>
            <Icon
              boxSize="1.3em"
              as={GiSmartphone}
              bg="base.secondary"
              p="2px 0px"
              borderRadius="3px"
            />
            <Text>+2349053610460</Text>
          </HStack>
          <HStack>
            <Icon
              boxSize="1.3em"
              bg="base.secondary"
              p="2px 0px"
              borderRadius="3px"
              as={LiaMapMarkerAltSolid}
            />
            <Text>No.21, Abeokuta Street, Garki, Abuja.</Text>
          </HStack>
          <HStack>
            <Icon
              boxSize="1.3em"
              bg="base.secondary"
              p="2px 0px"
              borderRadius="3px"
              as={MdAlternateEmail}
            />
            <Text>info@bsrproperty365.com</Text>
          </HStack>
          <VStack mt="30px" align="flex-start">
            <Text fontWeight="800" fontSize="18px">
              Follow out social media
            </Text>
            <HStack>
              <Circle
                border="1px solid"
                borderColor="base.border4"
                cursor="pointer"
                w="50px"
                h="50px"
                role="group"
                transition="all .3s ease-out"
                _hover={{ borderColor: "base.primary" }}
              >
                <Icon
                  as={FaFacebookF}
                  transition="all .3s ease-out"
                  _groupHover={{ color: "base.primary" }}
                />
              </Circle>
              <Circle
                border="1px solid"
                borderColor="base.border4"
                cursor="pointer"
                w="50px"
                h="50px"
                role="group"
                transition="all .3s ease-out"
                _hover={{ borderColor: "base.primary" }}
              >
                <Icon
                  as={FaTwitter}
                  transition="all .3s ease-out"
                  _groupHover={{ color: "base.primary" }}
                />
              </Circle>
              <Circle
                border="1px solid"
                borderColor="base.border4"
                cursor="pointer"
                w="50px"
                h="50px"
                role="group"
                transition="all .3s ease-out"
                _hover={{ borderColor: "base.primary" }}
              >
                <Icon
                  as={FaLinkedinIn}
                  transition="all .3s ease-out"
                  _groupHover={{ color: "base.primary" }}
                />
              </Circle>
              <Circle
                border="1px solid"
                borderColor="base.border4"
                cursor="pointer"
                w="50px"
                h="50px"
                role="group"
                transition="all .3s ease-out"
                _hover={{ borderColor: "base.primary" }}
              >
                <Icon
                  as={FaInstagram}
                  transition="all .3s ease-out"
                  _groupHover={{ color: "base.primary" }}
                />
              </Circle>
            </HStack>
          </VStack>
        </VStack>
        <HStack alignSelf="stretch" flex="1" align="flex-start" gap="40px">
          <VStack align="flex-start">
            <Text fontWeight="800" mb="30px" fontSize="18px">
              Locations
            </Text>
            <Text textStyle="footerLinkText">Abuja</Text>
            <Text textStyle="footerLinkText">Lagos</Text>
            <Text textStyle="footerLinkText">Kano</Text>
            <Text textStyle="footerLinkText">Port Harcourt</Text>
            <Text textStyle="footerLinkText">Enugu</Text>
          </VStack>
          <VStack align="flex-start">
            <Text fontWeight="800" mb="30px" fontSize="18px">
              Categories
            </Text>
            <Text textStyle="footerLinkText">House</Text>
            <Text textStyle="footerLinkText">Apartments/Flats</Text>
            <Text textStyle="footerLinkText">Land/Plots</Text>
            <Text textStyle="footerLinkText">Commercial Property</Text>
          </VStack>
        </HStack>
        <VStack
          align="flex-start"
          flex="1"
          flexBasis={["", "", "100%", "", "0"]}
          mt={["", "", "60px", "", "0"]}
          display={["none", "none", "block"]}
        >
          {data?.data?.map((datum, ind) => {
            return (
              <NewListings
                key={ind}
                id={datum?._id}
                thumbnail={datum?.images[0]}
                title={datum?.title}
                amount={datum?.price?.toLocaleString() || 0}
              />
            );
          })}
        </VStack>
      </HStack>
      <HStack bg="blue.500" p="20px">
        <Text color="base.white" w="fit-content" mx="auto" fontWeight="500">
          © 2023 | BSR Property.
        </Text>
      </HStack>
    </Box>
  );
};

export default Footer;

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  Text,
  Link,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { GiCheckMark } from "react-icons/gi";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { PublicRoutes } from "../../routes/Routes";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  register,
  signInToGoogle,
} from "../../services/authentication/authentication.service";
import { Field, Form, Formik } from "formik";
import toast from "react-hot-toast";
import { useAppStore } from "../../store/store";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

const Register = () => {
  const setUserIsLoggedIn = useAppStore((state) => state.setUserIsLoggedIn);
  const setUserIsAdmin = useAppStore((state) => state.setUserIsAdmin);
  const setUserData = useAppStore((state) => state.setUserData);
  const setUserToken = useAppStore((state) => state.setUserToken);

  const navigate = useNavigate();
  const { mutate: registerMutate, isPending } = useMutation({
    mutationKey: ["register"],
    mutationFn: (values) => {
      const res = register(
        values?.name,
        values?.email,
        values?.phone_number,
        values?.password,
        values?.confirm_password
      );
      return res;
    },
    onSuccess(data) {
      localStorage.setItem(
        "BRSUserData",
        JSON.stringify(data?.data?.data?.user)
      );
      localStorage.setItem("BRSUserToken", JSON.stringify(data?.data?.token));
      setUserIsLoggedIn(true);
      setUserData(data?.data?.data?.user);
      setUserToken(data?.data?.token);
      if (data?.data?.data?.user.role === "admin") {
        setUserIsAdmin(true);
      }
      navigate("/account/listings");
    },
    onError(data) {
      // toast.error(data?.response?.data?.message);
      toast.error("An error occurred");
    },
  });

  function validateValues(values) {
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
    let errors = {};
    if (!values.name) {
      errors.name = "Name is required";
    } else if (!values.email) {
      errors.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    } else if (
      String(values.phone_number).length < 10 ||
      String(values.phone_number).length > 11
    ) {
      errors.phone_number = "Invalid Phone Number";
    } else if (!passwordRegex.test(values.password)) {
      errors.password =
        "Min eight characters, at least one uppercase letter, one lowercase letter and one number";
    } else if (values.confirm_password !== values.password) {
      errors.confirm_password = "Password does not match";
    }
    return errors;
  }

  return (
    <Box>
      <HStack bg="base.secondary" pt="80px" w="100%" h="200px">
        <Text color="base.white" fontSize="40px" fontWeight="800" mx="auto">
          Register
        </Text>
      </HStack>
      <HStack
        align="flex-start"
        className="p-[2rem] flex max-[860px]:!flex-col !gap-[1rem]"
      >
        <Box className="w-full flex flex-col gap-[1rem] ">
          <Formik
            initialValues={{
              name: "",
              email: "",
              phone_number: "",
              password: "",
              confirm_password: "",
            }}
            validate={validateValues}
            onSubmit={async (values, actions) => {
              // await handleRegistration({ ...values });
              registerMutate(values);
              actions.setSubmitting(false);
            }}
          >
            {(props) => (
              <Form className="w-full flex flex-col gap-[1rem]">
                <Field name="name">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.name && form.touched.name}
                    >
                      <FormLabel className="!m-0 !mb-[0.15rem] ">
                        Name
                      </FormLabel>
                      <Input {...field} placeholder="name" />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel className="!m-0 !mb-[0.15rem] ">
                        Email
                      </FormLabel>
                      <Input {...field} placeholder="email" type="email" />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="phone_number">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        form.errors.phone_number && form.touched.phone_number
                      }
                    >
                      <FormLabel className="!m-0 !mb-[0.15rem] ">
                        Phone Number
                      </FormLabel>
                      <Input
                        {...field}
                        placeholder="Phone Number"
                        type="number"
                      />
                      <FormErrorMessage>
                        {form.errors.phone_number}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel className="!m-0 !mb-[0.15rem] ">
                        Password
                      </FormLabel>
                      <Input
                        {...field}
                        placeholder="Password"
                        type="password"
                      />
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="confirm_password">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        form.errors.confirm_password &&
                        form.touched.confirm_password
                      }
                    >
                      <FormLabel className="!m-0 !mb-[0.15rem] ">
                        Confirm Password
                      </FormLabel>
                      <Input
                        {...field}
                        placeholder="Confirm Password"
                        type="password"
                      />
                      <FormErrorMessage>
                        {form.errors.confirm_password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  mt={4}
                  colorScheme="teal"
                  isLoading={isPending}
                  type="submit"
                >
                  Register
                </Button>
              </Form>
            )}
          </Formik>
          <div className="flex items-center gap-[3px]  ">
            <div className="w-full h-[1px] bg-orange"></div>
            <Text className="text-grey font-[600]">Or</Text>
            <div className="w-full h-[1px] bg-orange"></div>
          </div>
          <GoogleLogin
            onSuccess={async (credentialResponse) => {
              try {
                const decoded = jwtDecode(credentialResponse.credential);
                const res = await signInToGoogle(decoded?.email, decoded?.name);
                localStorage.setItem(
                  "BRSUserData",
                  JSON.stringify(res?.data?.data?.user)
                );
                localStorage.setItem(
                  "BRSUserToken",
                  JSON.stringify(res?.data?.token)
                );
                setUserIsLoggedIn(true);
                setUserData(res?.data?.data?.user);
                setUserToken(res?.data?.token);
                if (res?.data?.data?.user.role === "admin") {
                  setUserIsAdmin(true);
                }
                navigate("/account/listings");
              } catch (e) {
                toast.error(e?.message);
              }
            }}
            onError={() => {
              toast.error("Login Failed, Please try again later!");
            }}
          />
          <Text>
            By continuing I understand and agree with Property24’s{" "}
            <Link className="!text-orange font-[700] ">Terms & Conditions</Link>{" "}
            and{" "}
            <Link className="!text-orange font-[700] ">Privacy Policy.</Link>
          </Text>
        </Box>
        <Box className="w-full pt-[2rem] pr-[2rem] flex flex-col ">
          <Text>Complete your registration now and get these benefits:</Text>
          <Text>Register now and get all these benefits:</Text>
          <HStack>
            <Icon as={GiCheckMark} />
            <Text>Manage and view your property alerts</Text>
          </HStack>
          <HStack>
            <Icon as={GiCheckMark} />
            <Text>Favourite and add notes to properties</Text>
          </HStack>
          <HStack>
            <Icon as={GiCheckMark} />
            <Text>Quickly contact agents with saved details</Text>
          </HStack>
          <Text>
            Already have an account?{" "}
            <Link
              className="!text-orange font-[700] "
              as={ReactRouterLink}
              to={PublicRoutes.LOGIN}
            >
              Log in.
            </Link>
          </Text>
        </Box>
      </HStack>
    </Box>
  );
};

export default Register;

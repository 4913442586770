import { Box } from "@chakra-ui/react";
import React from "react";
import BuyOptions from "./BuyOptions";
import RentOptions from "./RentOptions";
import SellOptions from "./SellOptions";
import CommercialOptions from "./CommercialOptions";
import AdviceOptions from "./AdviceOptions";
import AboutOptions from "./AboutOptions";

const DesktopMenu = ({ show, compRef, option }) => {
  const shouldShowMenu = show ? "block" : "none";

  return (
    <Box
      ref={compRef}
      boxShadow="0px 6px 20px 0px rgba(42, 57, 70, 0.08)"
      display={shouldShowMenu}
      pos="absolute"
      top="79px"
      bg="#fff"
      w="100%"
      h="200px"
      justifyContent="center"
      transition="all .5s ease"
      animation={
        show
          ? "fade-in .2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both"
          : "fade-out .2s ease-out both"
      }
    >
      {option === "buy" && <BuyOptions />}
      {option === "rent" && <RentOptions />}
      {option === "sell" && <SellOptions />}
      {option === "commercial" && <CommercialOptions />}
      {option === "advice" && <AdviceOptions />}
      {option === "about" && <AboutOptions />}
    </Box>
  );
};

export default DesktopMenu;

import axios from "axios";

const AxiosReq = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_SERVER,
});

AxiosReq.interceptors.request.use((res) => {
  const userToken = localStorage.getItem("BRSUserToken");
  const token = userToken ? JSON.parse(userToken) : "";
  res.headers.Authorization = `Bearer ${token}`;
  return res;
});

export const register = (name, email, phoneNum, password, confirmPassword) =>
  AxiosReq.post(`users/signup`, {
    name,
    email,
    password,
    confirmPassword,
    phoneNum,
  });
export const login = (email, password) =>
  AxiosReq.post(`users/login`, {
    email,
    password,
  });
export const signInToGoogle = (email, name) =>
  AxiosReq.post(`users/loginWithGoogle`, {
    email,
    name,
  });

import React, { Suspense, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AdminRoutes, PublicRoutes } from "./Routes";
import { Text } from "@chakra-ui/react";
import MiniNav from "../components/Menu/MiniNav/MiniNav";
import { useAppStore } from "../store/store";
import AdminSideBar from "../components/layout/AdminSideBar/AdminSideBar";

const Users = React.lazy(() => import("../pages/admin/Users"));

const AuthRouter = () => {
  const isAuth = JSON.parse(localStorage.getItem("BRSUserToken"));
  const userData = JSON.parse(localStorage.getItem("BRSUserData"));
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuth) {
      navigate(PublicRoutes.HOME);
    } else if (
      userData?.role !== "admin" &&
      location.pathname.includes("admin")
    ) {
      navigate(PublicRoutes.HOME);
      console.log("admin");
    }
  });

  return (
    <Suspense fallback={<Text>Loading...</Text>}>
      <div className="flex flex-row h-full">
        <AdminSideBar />
        <Outlet />
      </div>
    </Suspense>
  );
};

export const adminProtectedRoutes = [
  {
    path: AdminRoutes.BASE,
    element: <AuthRouter />,
    children: [
      {
        path: AdminRoutes.USERS,
        name: "Users",
        element: <Users />,
      },
    ],
  },
];

import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import Layout from "./components/layout/Layout";
import AppProviders from "./components/providers/AppProviders";
import "./index.css";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 0 } },
    mutationCache: new MutationCache({
      onError(error) {
        console.log("Error!!", error);
        // toast.error(error.message);
      },
      // onSuccess() {
      //   toast.success("Successful!");
      // },
    }),
    queryCache: new QueryCache({
      // onError(error) {
      //   toast.error(error.message);
      // },
      // onSuccess() {
      //   toast.success("Successful!");
      // },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Layout>
        <AppProviders />
      </Layout>
    </QueryClientProvider>
  );
}

export default App;

const RadioBtn = ({
  id,
  name,
  text,
  value,
  disabled,
  searchParams,
  setSearchParams,
  field,
}) => {
  let type =
    name === "offerType" ? "offerType" : name === "type" ? "propertyType" : "";

  return (
    <div className="flex gap-[5px] items-center !cursor-pointer group/radio w-fit">
      <input
        {...field}
        className="cursor-pointer "
        type="radio"
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        checked={searchParams && searchParams[type] === value.toLowerCase()}
        onChange={(e) => {
          if (e.target.checked) {
            setSearchParams((prev) => {
              return { ...prev, [type]: value.toLowerCase() };
            });
          }
        }}
      />
      <label
        className={`${
          disabled
            ? "!text-otherGrey"
            : "group-hover/radio:text-purple cursor-pointer "
        } transition-all  `}
        for={id}
      >
        {text}
      </label>
    </div>
  );
};

export default RadioBtn;

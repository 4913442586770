import { Text } from "@chakra-ui/react";
import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Login from "../pages/web/Login";
import Register from "../pages/web/Register";
import ViewListings from "../pages/web/ViewListings";
import ViewProperty from "../pages/web/ViewProperty";
import ViewUserProfile from "../pages/web/ViewUserProfile";
import { PublicRoutes } from "./Routes";
import ViewPlans from "../pages/web/ViewPlans";
import Photos from "../pages/web/Photos";
import SingleBlog from "../pages/web/SingleBlog";

const Home = React.lazy(() => import("../pages/web/Home"));

const WebRouter = () => {
  return (
    <Suspense fallback={<Text>Loading...</Text>}>
      <Outlet />
    </Suspense>
  );
};

export const publicRoutes = [
  {
    path: PublicRoutes.HOME,
    element: <WebRouter />,
    children: [
      {
        path: PublicRoutes.HOME,
        name: "Home",
        element: <Home />,
      },
      {
        path: PublicRoutes.LOGIN,
        name: "Login",
        element: <Login />,
      },
      {
        path: PublicRoutes.REGISTER,
        name: "Register",
        element: <Register />,
      },
      {
        path: PublicRoutes.VIEWLISTINGS,
        name: "ViewListings",
        element: <ViewListings />,
      },
      {
        path: PublicRoutes.LISTING,
        name: "Listing",
        element: <ViewProperty />,
      },
      {
        path: PublicRoutes.VIEWPROFILE,
        name: "ViewProfile",
        element: <ViewUserProfile />,
      },
      {
        path: PublicRoutes.PLANS,
        name: "ViewPlans",
        element: <ViewPlans />,
      },
      {
        path: PublicRoutes.PHOTOS,
        name: "Photos",
        element: <Photos />,
      },
      {
        path: PublicRoutes.BlogDetail,
        name: "/details/:slug",
        element: <SingleBlog />,
      },
      {
        path: "*",
        name: "Invalid",
        element: <Navigate to="/" replace={true} />,
      },
    ],
  },
];

import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { PiCaretDownThin } from "react-icons/pi";
import { LuFileSignature } from "react-icons/lu";
import { Link } from "react-router-dom";

const RentItem = () => {
  return (
    <AccordionItem border="none">
      <AccordionButton
        h="60px"
        color="text.main"
        borderBottom="1px solid"
        borderColor="base.faint"
        _hover={{ bg: "inherit" }}
      >
        <Box
          as="span"
          flex="1"
          textAlign="left"
          fontWeight="500"
          fontSize="18px"
          className="flex"
        >
          <LuFileSignature className="mx-2 mt-1 text-amber-600" />
          Rent
        </Box>
        <AccordionIcon as={PiCaretDownThin} color="text.main" />
      </AccordionButton>
      <AccordionPanel px="0" py="0">
        <Link
          to={"/view-listing?"}
          state={{
            offerType: "on-rent",
            propertyType: "house",
          }}
        >
          <Box
            h="60px"
            w="100%"
            pl="30px"
            color="text.main"
            borderBottom="1px solid"
            borderColor="base.faint"
            fontSize="18px"
            display="flex"
            alignItems="center"
            fontWeight="500"
            cursor="pointer"
          >
            Houses for Rent
          </Box>
        </Link>
        <Link
          to={"/view-listing?"}
          state={{
            offerType: "on-rent",
            propertyType: "apartments",
          }}
        >
          <Box
            h="60px"
            w="100%"
            pl="30px"
            color="text.main"
            borderBottom="1px solid"
            borderColor="base.faint"
            fontSize="18px"
            display="flex"
            alignItems="center"
            fontWeight="500"
            cursor="pointer"
          >
            Apartment/Flats for Rent
          </Box>
        </Link>
        <Link
          to={"/view-listing?"}
          state={{
            offerType: "on-rent",
            propertyType: "commercial",
          }}
        >
          <Box
            h="60px"
            w="100%"
            pl="30px"
            color="text.main"
            borderBottom="1px solid"
            borderColor="base.faint"
            fontSize="18px"
            display="flex"
            alignItems="center"
            fontWeight="500"
            cursor="pointer"
          >
            Commercial Property to Rent
          </Box>
        </Link>

        {/* <Box
          h="60px"
          w="100%"
          pl="30px"
          color="text.main"
          borderBottom="1px solid"
          borderColor="base.faint"
          fontSize="18px"
          display="flex"
          alignItems="center"
          fontWeight="500"
          cursor="pointer"
        >
          Retirement Property to Rent
        </Box> */}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default RentItem;

export const colors = {
  text: {
    main: "#314352",
    faint: "#7D7C7C",
  },
  bg: {
    white: "#f7fafd",
    white1: "rgba(253,253,254, 1)",
    gray: "#f7fafc",
  },
  blue: {
    50: "#dee8f7",
    100: "#39A7FF",
    500: "#2c3b49",
  },
  gray: {
    100: "#9EA5B1",
  },
  base: {
    black: "#000000",
    white: "#fff",
    primary: "#e1864a",
    secondary: "#537cd8",
    border: "#e3e3e3",
    border1: "#D5E3EE",
    border3: "#f2f5f7",
    border4: "#566572",
    placeholder: "#b8bbc0",
    faint: "#f1f4f8",
  },
};

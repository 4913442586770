import { Link } from "react-router-dom";
import { LeftArrowSvg, RightArrowSvg } from "./svg";
import { featuredListings } from "../../mock/featuredData";
import PropertyCard from "./PropertyCard";
import { truncateText } from "../../helper/helpers";

const LongSlider = ({ data, state, title, link, linkTitle }) => {
  return (
    <div className="MiddlePart w-full mt-[70px]">
      <div className="flex items-center justify-between max-lg:justify-center">
        <h1 className="text-[40px] max-lg:text-[20px] font-[700]  ">{title}</h1>
        <div className="max-lg:hidden flex gap-[1rem]">
          <Link
            to={link}
            state={state && state}
            className="px-[20px] py-[10px] rounded-[10px] bg-orange font-[700] text-[20px] text-white truncate max-w-[320px] active:scale-[0.9] hover:brightness-[1.1] transition-all duration-[300] ease"
          >
            {linkTitle}
          </Link>
          <div className="flex gap-[1rem]">
            <Link
              to={link}
              state={state && state}
              className="h-[50px] w-[50px] rounded-[10px] bg-lightGrey flex items-center justify-center cursor-pointer active:scale-[0.9] transition-all duration-[300] ease "
            >
              <LeftArrowSvg className={""} />
            </Link>
            <Link
              to={link}
              state={state && state}
              className="h-[50px] w-[50px] rounded-[10px] bg-lightGrey flex items-center justify-center cursor-pointer active:scale-[0.9] transition-all duration-[300] ease "
            >
              <RightArrowSvg className={""} />
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full flex gap-[1rem] mt-[2rem] lg:mt-[3rem] pb-[10px] overflow-x-auto">
        {data
          ? data?.map((datum, index) => {
              return (
                <PropertyCard
                  data={datum}
                  id={datum?._id}
                  thumbnail={datum?.images[0]}
                  title={datum?.title}
                  amount={datum?.price?.toLocaleString() || 0}
                  desc={truncateText(datum?.description, 10)}
                  className={"max-w-[300px] max-md:!max-w-full flex-1"}
                />
              );
            })
          : featuredListings?.map((props) => {
              return (
                <PropertyCard
                  {...props}
                  className={
                    "max-w-[300px] !min-w-[280px] max-md:!max-w-full flex-1 max-h-[400px]"
                  }
                />
              );
            })}
      </div>
      <div className="lg:hidden w-full mt-[2rem] justify-between flex gap-[1rem]">
        <Link
          to={link}
          className="px-[20px] py-[10px] rounded-[10px] bg-orange font-[700] text-[20px] text-white truncate max-w-[320px] active:scale-[0.9] hover:brightness-[1.1] transition-all duration-[300] ease"
        >
          {linkTitle}
        </Link>
        <div className="flex gap-[1rem]">
          <div className="h-[50px] w-[50px] rounded-[10px] bg-lightGrey flex items-center justify-center cursor-pointer active:scale-[0.9] transition-all duration-[300] ease ">
            <LeftArrowSvg className={""} />
          </div>
          <div className="h-[50px] w-[50px] rounded-[10px] bg-lightGrey flex items-center justify-center cursor-pointer active:scale-[0.9] transition-all duration-[300] ease ">
            <RightArrowSvg className={""} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LongSlider;

import React, { useEffect } from "react";
import AppRouter from "../../routes/AppRouter";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { theme } from "../../styles/theme/theme";
import DesktopHeader from "../layout/Header/DesktopHeader";
import MobileHeader from "../layout/Header/MobileHeader";
import Footer from "../layout/Footer/Footer";
import {
  QueryClient,
  QueryClientProvider,
  MutationCache,
  QueryCache,
} from "@tanstack/react-query";
import toast, { Toaster } from "react-hot-toast";
import ScrollToTop from "./ScrollToTop";
import { useAppStore } from "../../store/store";
import { useLocation } from "react-router-dom";

const AppProviders = () => {
  const location = useLocation();
  const userData = JSON.parse(localStorage.getItem("BRSUserData"));
  const isUserAdmin = useAppStore((state) => state.isUserAdmin);

  const CheckIFAdmin = () => {
    if (isUserAdmin && location.pathname.includes("admin")) {
      return true;
    }
    return false;
  };

  return (
    <ChakraProvider theme={theme}>
      <Toaster position="top-right" />
      <ScrollToTop>
        {!CheckIFAdmin() && <DesktopHeader />}
        {!CheckIFAdmin() && <MobileHeader />}
        <Box minH="100vh" className="h-full bg-[#f8fafd]">
          <AppRouter />
        </Box>
        {!CheckIFAdmin() && <Footer />}
      </ScrollToTop>
    </ChakraProvider>
  );
};

export default AppProviders;

import { Button } from "@chakra-ui/react";
import { CircleMarkSvg, RightArrowSvg } from "../../components/atoms/svg";
import PaystackPop from "@paystack/inline-js";
import { useAppStore } from "../../store/store";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { updateUserApi } from "../../services/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
const ViewPlans = () => {
  const userData = useAppStore((state) => state.userData);
  const isUserLoggedIn = useAppStore((state) => state.isUserLoggedIn);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: updateUserMutate, isPending } = useMutation({
    mutationKey: ["updateUser"],
    mutationFn: (values) => {
      const res = updateUserApi(userData._id, values);
      return res;
    },
    async onSuccess(data) {
      await queryClient.invalidateQueries("getUserData", userData?._id);

      navigate("/account/listings");
    },
    onError(error) {
      toast.error("Sorry, an error occurred, please contact support!");
    },
  });

  const payWithPaystack = (amount, name) => {
    if (isUserLoggedIn) {
      const paystack = new PaystackPop();
      paystack.newTransaction({
        key: process.env.REACT_APP_PAYSTACK_KEY,
        amount: amount,
        email: userData?.email,
        firstname: userData?.name?.split(" ")[0] || "",
        lastname: userData?.name?.split(" ")[1] || "",
        callback: function (response) {
          if (response?.status === "success") {
            updateUserMutate({ role: "agent", plan: name });
          }
        },
      });
    } else {
      toast.error("You need to have an account first!");
      navigate("/register");
    }
  };

  // if (isUserLoggedIn && userData?.role === "agent") return <div></div>;

  return (
    <div className="flex pt-[120px] pb-[50px] max-md:!px-[20px] px-[50px] container m-auto gap-[30px] max-w-[1300px] ">
      <div className="flex flex-col w-full items-center gap-[0.5rem]">
        <h1 className="font-[800] text-[30px]  ">Listing Plans</h1>
        <p className="md:w-[50%] text-center">
          Over 6 million visits on bsrproperty365 each month. List your property
          on Nigeria's No.1 property website!
        </p>
        <div className="flex flex-row w-full mt-4 flex-wrap justify-center gap-[1.5rem]">
          <PriceBox
            index={1}
            name={"Beginner"}
            amount={0}
            oldAmount={0}
            paymentFunc={payWithPaystack}
            updateUserMutate={updateUserMutate}
          />
          <PriceBox
            index={2}
            name={"Intermediate"}
            amount={5000}
            oldAmount={15000}
            paymentFunc={payWithPaystack}
            updateUserMutate={updateUserMutate}
          />
          <PriceBox
            index={3}
            name={"Expert"}
            amount={10000}
            oldAmount={25000}
            paymentFunc={payWithPaystack}
            updateUserMutate={updateUserMutate}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewPlans;

const PriceBox = ({
  index,
  name,
  amount,
  oldAmount,
  paymentFunc,
  updateUserMutate,
}) => {
  const userData = useAppStore((state) => state.userData);
  const plans = [
    {
      index: 0,
      name: "None",
    },
    {
      index: 1,
      name: "Beginner",
    },
    {
      index: 2,
      name: "Intermediate",
    },
    {
      index: 3,
      name: "Expert",
    },
  ];

  const checkIfUserHasAHigherPlan = (index) => {
    const userCurrentPlan = plans.filter((plan) => plan.name === userData.plan);
    if (index < userCurrentPlan[0].index) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="px-10 py-8 flex flex-col gap-[0.8rem] flex-[1_1_30%] max-[400px]:min-w-full min-w-[350px] max-w-[30%] rounded-[5px] border-[1px] border-lightGrey">
      <h5 className="text-[1.6rem] text-darkGrey font-[600]">{name}</h5>
      <p className="text-[1rem] text-otherGrey font-[300] line-through">
        N{oldAmount},-/Month
      </p>
      <p className="text-[1rem] text-grey font-[300]">
        <span className="font-[800] text-[2.5rem] text-darkGrey">
          N{amount}
        </span>
        /Month
      </p>
      <div className="w-full h-[1px] bg-lightGrey"></div>
      <div className="flex flex-col gap-[0.5rem] mb-6 h-[220px] justify-start">
        <MarkedText text="Reach NG's largest audience of buyers" />
        {index === 1 && (
          <MarkedText text="Only List up to 5 properties at a time" />
        )}
        {index === 2 && (
          <MarkedText text="Only List up to 10 properties at a time" />
        )}
        {index === 3 && (
          <MarkedText text="Can List up to an Unlimited number properties" />
        )}
        <MarkedText text="Dedicated support" />
        {/* <MarkedText text="Auto email alerts to relevant buyers" />
        <MarkedText text="Post unlimited photographs" />
        <MarkedText text="Receive instant lead notifications" />
        <MarkedText text="Track listing performance" />
        <MarkedText text="Offer to purchase assistance" /> */}
      </div>
      {userData?.plan === name ? (
        <Button className="w-full flex items-center justify-center bg-purple h-[50px] !py-[1.8rem] !px-[1.8rem] rounded-[3px] gap-[7px] hover:gap-[15px] border-none outline-none hover:border-none cursor-pointer">
          <p className="text-[18px] text-white font-[800] tracking-[1px] ">
            ACTIVE PLAN
          </p>
        </Button>
      ) : (
        <Button
          className="w-full flex items-center justify-center bg-orange h-[50px] !py-[1.8rem] !px-[1.8rem] rounded-[3px] gap-[7px] hover:gap-[15px] border-none outline-none hover:border-none cursor-pointer"
          onClick={() => {
            if (userData?.plan) {
              if (checkIfUserHasAHigherPlan(index)) {
                toast.error("You are already on a higher plan");
              } else {
                if (name === "Beginner") {
                  //if its free plan
                  updateUserMutate({ role: "agent", plan: name });
                } else {
                  paymentFunc(amount, name);
                }
              }
            }
          }}
        >
          <p className="text-[18px] text-white font-[800] tracking-[1px] ">
            SELECT PLAN
          </p>
          <RightArrowSvg />
        </Button>
      )}
      <p className="text-center mt-2 text-[0.9rem] text-darkGrey font-[300]">
        <span className="text-red font-[600]">*</span> Tax & other services
        included.
      </p>
    </div>
  );
};

const MarkedText = ({ text }) => {
  return (
    <div className="flex items-center gap-[10px]">
      <CircleMarkSvg className="" />
      <p className="text-[0.9rem] text-grey font-[300]">{text}</p>
    </div>
  );
};

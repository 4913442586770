import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const CommercialOptions = () => {
  return (
    <Box
      bg="#fff"
      w="70%"
      h="100%"
      display="flex"
      justifyContent="space-evenly"
      fontWeight="700"
      fontSize="15px"
      p="40px 0"
      margin="auto"
    >
      <Box>
        <Text mb="15px">Find Commercial Property</Text>
        <Box color="#537cd8" display="flex" flexDirection="column" gap="15px">
          <Link
            to={"/view-listing?"}
            state={{
              offerType: "on-sale",
              propertyType: "commercial",
            }}
          >
            <Text className="hover-underline-animation">
              Commercial Property for Sale
            </Text>
          </Link>
          <Link
            to={"/view-listing?"}
            state={{
              offerType: "on-rent",
              propertyType: "commercial",
            }}
          >
            <Text className="hover-underline-animation">
              Commercial Property to Rent
            </Text>
          </Link>
          <Link
            to={"/view-listing?"}
            state={{
              offerType: "on-sale",
              propertyType: "industrial",
            }}
          >
            <Text className="hover-underline-animation">
              Industrial Property for Sale
            </Text>
          </Link>
        </Box>
      </Box>
      <Box>
        <Box
          color="#537cd8"
          display="flex"
          flexDirection="column"
          gap="15px"
          mt="35px"
        >
          <Link
            to={"/view-listing?"}
            state={{
              offerType: "on-rent",
              propertyType: "industrial",
            }}
          >
            <Text className="hover-underline-animation">
              Industrial Property to Rent
            </Text>
          </Link>
        </Box>
      </Box>
      <Box>
        <Text mb="15px">Helpful Links</Text>
        <Box color="#537cd8" display="flex" flexDirection="column" gap="15px">
          <Text className="hover-underline-animation">
            Commercial Property News
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default CommercialOptions;

import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ videoUrl }) => {
  return (
    <div className="w-full">
      <ReactPlayer
        className="w-full mt-[0.5rem]"
        url={`https://www.youtube.com/watch?v=${videoUrl}`}
        controls
        width="100%"
        height="365px"
      />
    </div>
  );
};

export default VideoPlayer;

import React from "react";
import { useLocation, useParams } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import { sanityClient } from "../../client";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const SingleBlog = () => {
  const location = useLocation();
  const { article, author } = location.state;

  const serializers = {
    marks: {
      link: (props) => (
        <a className="text-[#f58807] no-underline" href={props.mark.href}>
          {props.children}
        </a>
      ),
    },
    types: {
      block: (props) => {
        switch (props.node.style) {
          case "h1":
            return (
              <h1 className="mb-8 text-5xl font-bold leading-tight text-gray-900">
                {props.children}
              </h1>
            );
          case "h2":
            return (
              <h2 className="mb-6 text-4xl font-bold leading-tight text-gray-800">
                {props.children}
              </h2>
            );
          case "h3":
            return (
              <h3 className="mb-4 text-3xl font-semibold leading-snug text-gray-700">
                {props.children}
              </h3>
            );
          case "h4":
            return (
              <h4 className="mb-3 text-2xl font-semibold leading-snug text-gray-600">
                {props.children}
              </h4>
            );
          case "h5":
            return (
              <h5 className="mb-2 text-xl font-semibold leading-snug text-gray-500">
                {props.children}
              </h5>
            );
          case "h6":
            return (
              <h6 className="mb-1 text-lg font-semibold leading-snug text-gray-400">
                {props.children}
              </h6>
            );
          case "blockquote":
            return (
              <blockquote className="p-6 my-8 italic bg-gray-100 border-l-4 border-gray-400 rounded-lg">
                {props.children}
              </blockquote>
            );
          default:
            return (
              <p className="mb-6 leading-relaxed text-gray-700">
                {props.children}
              </p>
            );
        }
      },
      code: (props) => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
    list: (props) => {
      const { type, children } = props;
      if (type === "bullet") {
        return <ul className="prose prose-2xl list-decimal">{children}</ul>;
      }
      if (type === "number") {
        return <ol>{children}</ol>;
      }
      return null;
    },
    listItem: (props) => <li>{props.children}</li>,
  };

  return (
    <div className="h-full px-5">
      <div className="flex items-center justify-center !w-full h-screen">
        <img
          className="h-[100vh] w-full object-cover rounded-lg shadow-lg "
          src={urlFor(article.mainImage.asset.url).url()}
          alt="..."
        />
      </div>

      <div className=" lg:px-[16em] py-10">
        <h2 className="text-5xl font-bold text-center text-gray-800 capitalize prose-h1:p-2">
          {article.title}
        </h2>
        <div className="flex justify-start pb-8">
          <img
            className="h-16 rounded-full w-14"
            src={author[0]?.authorImage}
            alt="..."
          />
          <p className="pt-1 pl-3 text-lg text-white capitalize">
            {author[0].name}
            <br />
            {author[0].bio[0].children[0].text}
          </p>
        </div>
        <article
          className=" w-full max-w-none text-gray-800 
          first-letter:text-[80px] text-lg
          first-letter:capitalize leading-10 tracking-wider first-letter:font-bold"
        >
          <BlockContent
            projectId="cpm93amn"
            dataset="production"
            blocks={article.body}
            imageOptions={{ w: 500, h: 500, fit: "max" }}
            serializers={serializers}
          />
          {/* {renderRichText(data.body)} */}
          {/* <hr className="" /> */}
        </article>
      </div>
    </div>
  );
};

export default SingleBlog;

import {
  Accordion,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { GrClose } from "react-icons/gr";
import BuyItem from "./BuyItem";
import { BiLogoFacebook, BiSupport } from "react-icons/bi";
import RentItem from "./RentItem";
import SellItem from "./SellItem";
import CommercialItem from "./CommercialItem";
import AdviceItem from "./AdviceItem";
import AboutItem from "./AboutItem";
import { FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { PublicRoutes } from "../../../routes/Routes";
import { Link as ReactRouterLink } from "react-router-dom";
import { MdOutlineAttachEmail } from "react-icons/md";

const MobileMenu = ({ onClose, isOpen }) => {
  return (
    <Box pos="absolute">
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            px="15px"
            borderBottomWidth="1px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bg="bg.white"
            borderBottom="none"
          >
            {" "}
            <Image src="/images/logo.png" h="70px" cursor="pointer" />
            <Center
              w="50px"
              h="50px"
              borderRadius="50%"
              border="1px solid"
              borderColor="base.border1"
              cursor="pointer"
            >
              <Icon as={GrClose} onClick={onClose} />
            </Center>
          </DrawerHeader>
          <DrawerBody p="0">
            <Accordion allowMultiple={false} allowToggle>
              <BuyItem />
              <RentItem />
              <SellItem />
              <CommercialItem />
              <AdviceItem />
              <AboutItem />
            </Accordion>
            <VStack
              p="16px"
              fontWeight="600"
              gap="7px"
              align="flex-start"
              mt="25px"
            >
              <Text className="flex" color="base.primary">
                <BiSupport className="mx-2 mt-1 text-gray-600" />
                Call Support
              </Text>
              <Text>+2349053610460</Text>
              <Text className="flex" color="base.primary">
                <MdOutlineAttachEmail className="mx-2 mt-1 text-gray-600" />
                Email Address
              </Text>
              <Text>info@bsrproperty365.com</Text>
            </VStack>
            <HStack my="40px" ml="16px">
              <Center
                w="50px"
                h="50px"
                borderRadius="50%"
                border="1px solid"
                borderColor="base.border1"
                cursor="pointer"
              >
                <Icon boxSize="1.3em" as={BiLogoFacebook} />
              </Center>
              <Center
                w="50px"
                h="50px"
                borderRadius="50%"
                border="1px solid"
                borderColor="base.border1"
                cursor="pointer"
              >
                <Icon boxSize="1.3em" as={FaTwitter} />
              </Center>
              <Center
                w="50px"
                h="50px"
                borderRadius="50%"
                border="1px solid"
                borderColor="base.border1"
                cursor="pointer"
              >
                <Icon boxSize="1.3em" as={FaLinkedinIn} />
              </Center>
              <Center
                w="50px"
                h="50px"
                borderRadius="50%"
                border="1px solid"
                borderColor="base.border1"
                cursor="pointer"
              >
                <Icon boxSize="1.3em" as={FaInstagram} />
              </Center>
            </HStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MobileMenu;

import { ProfileSvg } from "../../atoms/svg";
import NavItem from "./NavItem";
import { Image } from "@chakra-ui/react";
import { FaUsers } from "react-icons/fa";

const AdminSideBar = () => {
  return (
    <nav className="flex flex-col min-w-[280px] h-full border-dashed border-r-[1px] border-[#919eab3d] py-[24px] px-[20px] min-h-screen">
      <div className="mb-[16px] w-full">
        <div className="flex">
          <Image src="/images/logo.png" h="70px" cursor="pointer" />
        </div>
        <div className="bg-[#919eab1f] py-[16px] px-[20px] rounded-[12px] w-full h-[76px] flex items-center gap-[16px] mt-[1.5rem]">
          <div className="flex flex-row items-center justify-center rounded-full bg-[#00b8d9] w-[40px] h-[40px] text-[1.25rem] text-[white] font-[600]">
            IA
          </div>
          <div className="flex flex-col h-full justify-center">
            <p className="text-[#212b36]  text-[0.875rem] leading-[1.57143]">
              Iguana Admin
            </p>
            <p className="text-[#637381] text-[0.85rem] leading-[1.57143] font-[300] ">
              admin
            </p>
          </div>
        </div>
      </div>
      <h5 className="text-[#637381] text-[11px] leading-[1.5] font-[700] mt-[24px] px-[12px] mb-[8px]">
        DASHBOARD
      </h5>
      <div className="flex flex-col gap-[1px]">
        <NavItem mainTitle="Users" svg={<FaUsers />} />
      </div>
    </nav>
  );
};

export default AdminSideBar;

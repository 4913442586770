import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Photos = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    setPhotos(location?.state);
  }, [location]);

  if (photos.length < 1)
    return (
      <div className="container m-auto gap-[30px] max-w-[1300px] w-full flex items-center justify-center min-h-[100vh]">
        <p className=" font-500">No Images...</p>
      </div>
    );

  return (
    <div className="flex pt-[120px] pb-[50px] max-md:!px-[20px] px-[50px] container m-auto gap-[30px] max-w-[1300px] ">
      <div className="w-full h-full flex flex-col gap-2">
        <p
          className="font-[700] cursor-pointer"
          onClick={() => navigate(-1)}
        >{`<< Back`}</p>
        {photos.map((image, index) => {
          return (
            <img
              key={index}
              className="w-full h-full object-cover object-center"
              src={image}
              alt="house"
            />
          );
        })}
      </div>
    </div>
  );
};

export default Photos;

import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const BuyOptions = () => {
  return (
    <Box
      bg="#fff"
      w="70%"
      h="100%"
      display="flex"
      justifyContent="space-evenly"
      fontWeight="700"
      fontSize="15px"
      p="40px 0"
      margin="auto"
    >
      <Box>
        <Link
          to={"/view-listing?"}
          state={{
            offerType: "on-sale",
          }}
        >
          <Text mb="15px">Looking to Buy?</Text>
        </Link>
        <Box color="#537cd8" display="flex" flexDirection="column" gap="15px">
          <Link
            to={"/view-listing?"}
            state={{
              offerType: "on-sale",
            }}
          >
            <Text className="hover-underline-animation">Property for Sale</Text>
          </Link>
          <Link
            to={"/view-listing?"}
            state={{
              offerType: "on-sale",
              propertyType: "commercial",
            }}
          >
            <Text className="hover-underline-animation">
              Commercial Property for Sale
            </Text>
          </Link>
        </Box>
      </Box>
      <Box>
        <Text mb="15px">Helpful Links</Text>
        <Box color="#537cd8" display="flex" flexDirection="column" gap="15px">
          <Text className="hover-underline-animation">Buyers Guide</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default BuyOptions;

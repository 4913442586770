import { create } from "zustand";

export const useAppStore = create((set) => ({
  isUserLoggedIn: false,
  setUserIsLoggedIn: (value) => {
    set({ isUserLoggedIn: value });
  },
  isUserAdmin: false,
  setUserIsAdmin: (value) => {
    set({ isUserAdmin: value });
  },
  userData: null,
  setUserData: (userData) => {
    set({ userData: userData });
  },
  token: "",
  setUserToken: (token) => {
    set({ token: token });
  },
}));

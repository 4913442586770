import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    display: "flex",
    alignItems: "center",
    my: "1px",
    // px: "10px",
  },
  label: {
    // fontSize: "13px",
    // fontSize: "10px",
    display: "inline-block",
    // fontFamily: "mono", // change the font family of the label
  },
  control: {
    padding: 0, // change the padding of the control
    borderRadius: "4px", // change the border radius of the control
    boxSize: "14px",
    display: "inline-block",
    my: "auto",
    _hover: {
      bg: "none",
    },
    _checked: {
      bg: "blue.100",
      border: "none",
      _hover: {
        bg: "blue.100",
      },
    },
  },
  icon: {
    boxSize: "7px",
  },
});

export const checkboxStyles = defineMultiStyleConfig({ baseStyle });

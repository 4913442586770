import { Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const NavItem = ({ mainTitle, svg }) => {
  const [isActive, setIsActive] = useState(false);
  const [isWorking, setIsWorking] = useState(false);

  const location = useLocation();
  const pathname = location.pathname;
  useEffect(() => {
    if (pathname === "/swaps") {
      setIsWorking(true);
    } else {
      setIsWorking(false);
      setIsActive(false);
    }

    // console.log(pathname, drop);
  }, [pathname]);

  return (
    <div
      className={`NavItemDrop ${isActive && "active"} ${
        isWorking && "working"
      }`}
    >
      <Link to={"/dashboard/swaps"} className="w-full">
        <Button
          className="mainBtn w-full !h-[48px] hover:bg-[#17246114] !flex !justify-between !pl-[14px] !pr-[10px] rounded-[8px] !py-[8px] cursor-pointer"
          onClick={() => {
            setIsActive((prev) => !prev);
          }}
        >
          <div className="flex gap-[1rem] items-center">
            {svg}
            <p className="title text-[#637381] text-[0.875rem] leading-[1.57143] font-[400] normal-case">
              {mainTitle}
            </p>
          </div>
        </Button>
      </Link>
    </div>
  );
};

export default NavItem;

import { Box, Text } from "@chakra-ui/react";
import React from "react";

const AboutOptions = () => {
  return (
    <Box
      bg="#fff"
      w="70%"
      h="100%"
      display="flex"
      justifyContent="space-evenly"
      fontWeight="700"
      fontSize="15px"
      p="40px 0"
      margin="auto"
    >
      <Box>
        <Text mb="15px">Learn More?</Text>
        <Box color="#537cd8" display="flex" flexDirection="column" gap="15px">
          <Text className="hover-underline-animation">Our Blog</Text>
          <Text className="hover-underline-animation">Latest updates</Text>
        </Box>
      </Box>
      <Box>
        <Text mb="15px">Get in Touch</Text>
        <Box color="#537cd8" display="flex" flexDirection="column" gap="15px">
          <Text className="hover-underline-animation">Contact Us</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutOptions;

import { useEffect, useState } from "react";

const Pagination = ({ currentNo, setCurrentNo, totalNo }) => {
  const [inputValue, setInputValue] = useState(currentNo);

  useEffect(() => {
    setInputValue(currentNo);
  }, [currentNo]);

  return (
    <div className="flex items-center gap-4 justify-center lg:justify-start text-[0.8rem] md:text-[1rem] flex-wrap">
      <p className="">
        Page <span className="font-bold">{currentNo}</span> of{" "}
        <span className="font-bold">{totalNo}</span> | go to page
      </p>
      <input
        className="!w-[50px] text-[0.8rem] md:text-[1rem] border-b-[1px] border-[#284b80] outline-none"
        defaultValue={inputValue}
        onChange={(e) => {
          if (
            e.target.value &&
            currentNo !== e.target.value &&
            e.target.value >= 1 &&
            e.target.value <= totalNo
          ) {
            setCurrentNo(Number(e.target.value));
          }
        }}
        type="number"
        min={1}
        max={totalNo}
      />
      <div className="flex flex-row gap-[4px]">
        <div
          className="bg-grey rounded-[4px] p-[4px] px-[10px] cursor-pointer"
          style={{
            background: currentNo === 1 ? "#e5e7eb" : "#284b80",
            color: currentNo === 1 ? "#000" : "#fff",
          }}
          onClick={() => {
            if (currentNo > 1) {
              setCurrentNo(1);
            }
          }}
        >
          {"<<"}
        </div>
        <div
          className="bg-grey rounded-[4px] p-[4px] px-[10px] cursor-pointer"
          style={{
            background: currentNo === 1 ? "#e5e7eb" : "#284b80",
            color: currentNo === 1 ? "#000" : "#fff",
          }}
          onClick={() => {
            if (currentNo > 1) {
              setCurrentNo((prev) => {
                return prev - 1;
              });
            }
          }}
        >
          Prev
        </div>
        <div
          className="bg-grey rounded-[4px] p-[4px] px-[10px] cursor-pointer"
          style={{
            background: currentNo === totalNo ? "#e5e7eb" : "#284b80",
            color: currentNo === totalNo ? "#000" : "#fff",
          }}
          onClick={() => {
            if (currentNo < totalNo) {
              setCurrentNo((prev) => {
                return prev + 1;
              });
            }
          }}
        >
          Next
        </div>
        <div
          className="bg-grey rounded-[4px] p-[4px] px-[10px] cursor-pointer"
          style={{
            background: currentNo === totalNo ? "#e5e7eb" : "#284b80",
            color: currentNo === totalNo ? "#000" : "#fff",
          }}
          onClick={() => {
            if (currentNo < totalNo) {
              setCurrentNo(totalNo);
            }
          }}
        >
          {">>"}
        </div>
      </div>
    </div>
  );
};

export default Pagination;

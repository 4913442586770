export const PublicRoutes = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  VIEWLISTINGS: "/view-listing",
  LISTING: "/listing/:id",
  VIEWPROFILE: "/user/admin",
  PLANS: "/plans",
  PHOTOS: "/photos",
  BlogDetail: "/details",
};

export const ProtectedRoutes = {
  BASE: "/account",
  CREATE: "/account/create",
  LISTINGS: "/account/listings",
  FAVORITES: "/account/favorites",
  SETTINGS: "/account/settings",
};

export const AdminRoutes = {
  BASE: "/admin",
  USERS: "/admin/users",
};

import { defineStyleConfig } from "@chakra-ui/react";

export const buttonStyles = defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    // borderRadius: "3px",
    textTransform: "capitalize",
  },
  // Styles for the size variations
  sizes: {
    sm: {
      fontsize: "12px",
    },
    md: {
      fontsize: "16px",
    },
  },
  // Styles for the visual style variations
  variants: {
    primary: {
      border: "1px solid",
      borderColor: "base.border1",
      fontWeight: "600",
      padding: "8px 25px",
      borderRadius: "8px",
      transition: "0.3s",
      _hover: { borderColor: "base.secondary" },
    },
    secondary: {
      border: "1px solid",
      borderColor: "base.primary",
      fontWeight: "600",
      padding: "8px 25px",
      borderRadius: "8px",
      transition: "0.3s",
      _hover: { bg: "base.primary", color: "base.white" },
    },
    outline: {},
    nav: {
      p: "0 15px",
      cursor: "pointer",
      borderRadius: "6px",
      padding: "12px 15px",
      transition: "all .3s ease",
      _hover: { bg: "base.border3" },
    },
    menu: {
      padding: "4px 16px",
      borderRadius: "8px",
      fontWeight: "600",
      minHeight: "54px",
      flex: "1",
      border: "1px solid",
      borderColor: "base.border1",
      textAlign: "left",
      backgroundColor: "bg.white",
    },
  },
  // The default `size` or `variant` values
  defaultProps: {
    variant: "primary",
    size: "md",
  },
});

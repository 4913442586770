import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const SellOptions = () => {
  return (
    <Box
      bg="#fff"
      w="70%"
      h="100%"
      display="flex"
      justifyContent="space-evenly"
      fontWeight="700"
      fontSize="15px"
      p="40px 0"
      margin="auto"
    >
      <Box>
        <Text mb="15px">Looking to Sell?</Text>
        <Box color="#537cd8" display="flex" flexDirection="column" gap="15px">
          <Link to={"/account/create"}>
            <Text className="hover-underline-animation">
              Sell Your Property
            </Text>
          </Link>
          <Link to={"/account/create"}>
            <Text className="hover-underline-animation">
              List Your Property
            </Text>
          </Link>
        </Box>
      </Box>
      <Box>
        <Text mb="15px">Helpful Links</Text>
        <Box color="#537cd8" display="flex" flexDirection="column" gap="15px">
          <Text className="hover-underline-animation">Seller Guide</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default SellOptions;
